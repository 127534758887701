<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>主题管理</el-breadcrumb-item>
      <el-breadcrumb-item>主题列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="20">
          <el-form :inline="true">
            <el-form-item label="发布用户ID">
              <el-input
                clearable
                placeholder="搜索发布用户ID"
                v-model="add_auid"
              ></el-input>
            </el-form-item>

            <el-form-item label="搜索主题说明">
              <el-input
                clearable
                placeholder="搜索主题说明"
                v-model="theme_bz"
              ></el-input>
            </el-form-item>

            <el-form-item label="主题类型">
              <el-select v-model="theme_type_id" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in theme_typeArr"
                  :key="index2"
                  :label="item2.theme_title"
                  :value="item2.theme_type_id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="审核状态">
              <el-select v-model="check_status" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in check_statusArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.prop == 'post_content'">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">
                    <div v-html="scope.row[item.prop]"></div>
                  </div>
                  <div class="post_contentDiv">{{ scope.row[item.prop] }}</div>
                  >
                </el-tooltip>
              </div>

              <div v-else-if="item.prop == 'post_msg'">
                <el-button
                  type="success"
                  @click="onShowHtml(scope.row[item.prop])"
                  >查看描述</el-button
                >
              </div>
              <div v-else-if="item.prop == 'post_pic'" class="imgDiv">
                <el-image
                  style="width: 50px; height: 50px"
                  v-for="(item, index) in scope.row[item.prop]"
                  :key="index"
                  :src="item"
                  fit="fill"
                  @click="onOpenUrl(item)"
                ></el-image>
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="审核" width="100">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onCheck(scope.row)"
                  >审核</el-button
                >
                <el-button
                  v-if="scope.row['top_index'] > 0"
                  type="text"
                  style="color: red"
                  @click="onTopItem(scope.row, 0)"
                  >取消置顶</el-button
                >
                <!--
                <el-button v-else type="text" @click="onTopItem(scope.row, 2)"
                  >置顶</el-button
                >
                -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.theme_id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'theme_id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>

    <!--审核窗口-->
    <el-dialog
      v-if="checkItem"
      :close-on-click-modal="false"
      title="审核主题"
      :visible.sync="showCheckDlg"
      width="300"
      :before-close="handleCloseDlgByShenHe"
    >
      <div>
        <div class="checkItem">
          <div>主题ID:</div>
          <div>{{ checkItem.theme_id }}</div>
        </div>
        <div class="checkItem">
          <div>主题类型标题:</div>
          <div>{{ checkItem.theme_title }}</div>
        </div>

        <div class="checkItem">
          <div>审核状态:</div>
          <el-radio-group v-model="checkItem['check_status']">
            <el-radio
              v-for="(item, index) in check_statusArr"
              :key="index"
              :label="index"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="checkItem">
          <div>审核说明:</div>
          <el-input type="textarea" clearable v-model="checkItem.check_msg">
          </el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCheckDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveCheck">确 定</el-button>
      </div>
    </el-dialog>

    <!--显示详情预览-->
    <el-drawer :visible.sync="isShow" direction="rtl" size="50%">
      <div class="myHtml">
        <div v-html="showHtmlText"></div>
      </div>
    </el-drawer>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      showHtmlText: "",
      isShow: false,

      add_auid: "",

      theme_bz: "",
      check_status: "",
      theme_type_id: "",
      //显示审核订单
      showCheckDlg: false,
      //审核订单内容
      checkItem: null,
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
  `theme_id` int(11) NOT NULL AUTO_INCREMENT COMMENT '主题ID',
  `watch_count` int(11) NOT NULL DEFAULT '0' COMMENT '查看次数',
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `update_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP COMMENT '修改时间',
  `add_auid` int(11) NOT NULL DEFAULT '0' COMMENT '添加用户ID',
  `check_status` int(11) NOT NULL DEFAULT '0' COMMENT '审核状态(0=待审 1=已通过 2=通过待付款 3=已拒绝)',
  `check_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '审核说明',
  `check_userid` int(11) NOT NULL DEFAULT '0' COMMENT '审核帐号ID',
  `check_time` timestamp NULL DEFAULT NULL COMMENT '审核时间',
  `theme_status` int(11) NOT NULL DEFAULT '0' COMMENT '人数状态(0=未满 1=已满)',
  `need_where_id` int(11) NOT NULL DEFAULT '0' COMMENT '报名条件ID',
  `need_num` int(11) NOT NULL DEFAULT '1' COMMENT '参与人数',
  `theme_type_id` int(11) NOT NULL DEFAULT '0' COMMENT '主题类型ID',
  `theme_bz` varchar(255) NOT NULL DEFAULT '' COMMENT '主题说明',
  `theme_code` varchar(255) NOT NULL DEFAULT '' COMMENT '约会暗号',
  `to_city` varchar(255) NOT NULL DEFAULT '' COMMENT '约会城市',
  `location_name` varchar(255) NOT NULL DEFAULT '' COMMENT '地址名称',
  `location_address` varchar(255) NOT NULL DEFAULT '' COMMENT '具体地址',
  `location_lat` decimal(10,7) NOT NULL DEFAULT '0.0000000' COMMENT '所在地纬度',
  `location_lon` decimal(10,7) NOT NULL DEFAULT '0.0000000' COMMENT '所在地经度',
  `pay_type` int(11) NOT NULL DEFAULT '0' COMMENT '费用承担类型(0=自付 1=对方付 2=AA制)',
  `theme_start_time` timestamp NULL DEFAULT NULL COMMENT '开始时间',
  `theme_end_time` timestamp NULL DEFAULT NULL COMMENT '结束时间',
  `apply_end_time` timestamp NULL DEFAULT NULL COMMENT '报名截止',
  `show_contact` int(11) NOT NULL DEFAULT '0' COMMENT '显示联系方式(0=不显示 1=显示)',
  `chat_title` varchar(255) NOT NULL DEFAULT '' COMMENT '聊天室名称',
  `chat_disable` int(11) NOT NULL DEFAULT '0' COMMENT '禁止群聊(0=正常 1=禁用)',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
       */
      check_statusArr: ["待审核", "已通过", "通过待付款", "已拒绝"],
      propList: [
        {
          prop: "theme_id",
          label: "主题ID",
        },
        {
          prop: "watch_count",
          label: "查看次数",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "update_time",
          label: "更新时间",
          width: 200,
          type: "time",
        },
        {
          prop: "add_auid",
          label: "添加用户ID",
        },
        {
          prop: "userName",
          label: "添加用户昵称",
          width: 150,
        },
        {
          prop: "check_status",
          label: "审核状态",
          type: "array",
          data: ["待审核", "已通过", "通过待付款", "已拒绝"],
        },
        {
          prop: "check_msg",
          label: "审核说明",
          type: "textarea",
          width: 200,
        },
        {
          prop: "check_userid",
          label: "审核帐号ID",
        },
        {
          prop: "checkUserName",
          label: "审核帐号昵称",
          width: 150,
        },

        {
          prop: "check_time",
          label: "审核时间",
          width: 200,
          type: "time",
        },

        {
          prop: "theme_status",
          label: "人数状态",
          width: 150,
          type: "array",
          data: ["未满", "已满"],
        },

        {
          prop: "need_where_id",
          label: "报名条件ID",
        },
        {
          prop: "need_num",
          label: "参与人数",
        },
        {
          prop: "theme_type_id",
          label: "主题类型ID",
          width: 100,
        },
        {
          prop: "theme_title",
          label: "主题类型标题",
          width: 150,
        },

        {
          prop: "theme_bz",
          label: "主题说明",
          type: "textarea",
          width: 200,
        },
        {
          prop: "theme_code",
          label: "约会暗号",
          type: "textarea",
          width: 200,
        },
        {
          prop: "to_city",
          label: "约会城市",
          width: 200,
        },

        {
          prop: "location_name",
          label: "地址名称",
          width: 200,
        },
        {
          prop: "location_address",
          label: "具体地址",
          width: 200,
        },
        {
          prop: "location_lat",
          label: "所在地纬度",
          width: 200,
        },
        {
          prop: "location_lon",
          label: "所在地经度",
          width: 200,
        },
        {
          prop: "pay_type",
          label: "费用承担类型",
          width: 200,
          type: "array",
          data: ["自付", "对方付", "AA制"],
        },
        {
          prop: "theme_start_time",
          label: "开始时间",
          type: "time",
        },

        {
          prop: "theme_end_time",
          label: "结束时间",
          type: "time",
        },
        {
          prop: "apply_end_time",
          label: "报名截止",
          width: 200,
          type: "time",
        },
        {
          prop: "show_contact",
          label: "显示联系方式",
          type: "array",
          width: 200,
          type: ["不显示", "显示"],
        },
        {
          prop: "chat_title",
          label: "聊天室名称",
        },
        {
          prop: "chat_disable",
          label: "是否禁止群聊",
          width: 200,
          type: "array",
          type: ["正常", "禁用"],
        },
        {
          prop: "bz",
          label: "备注",
        },
      ],

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,

      theme_typeArr: [],
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getThemeTypeList();
    this.getList();
  },

  methods: {
    async getThemeTypeList() {
      const res = await this.$post(`getThemeTypeList/getThemeTypeList`);
      console.log(res);
      if (res.code !== 200) return;

      this.theme_typeArr = res.data || [];
    },
    //调整table 高度
    getH() {
      return window.innerHeight - 270;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,

        theme_type_id: this.theme_type_id,
        add_auid: this.add_auid,
        theme_bz: this.theme_bz,
        check_status: this.check_status,
      };
      const res = await this.$post(`YHThemeList/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let obj = {
        theme_id: this.editItem.theme_id,
        data: JSON.parse(JSON.stringify(this.editItem)),
      };

      const res = await this.$post(`YHThemeList/saveData`, obj);
      //console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        theme_id: item.theme_id,
      };
      console.log(obj2);
      const res = await this.$post(`YHThemeList/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    getUrlList(str) {
      console.log(str);
      if (!str || str.length < 1) return [];
      return str.split(",");
    },

    //打开地址
    onOpenUrl(url) {
      console.log(url);
      if (!url || url.length < 1) return;
      if (url.indexOf("http") != 0) {
        url = this.$imgUrl + url;
      }
      window.open(url, "_black");
    },
    //审核
    onCheck(item) {
      console.log(item);
      this.checkItem = JSON.parse(JSON.stringify(item));
      this.showCheckDlg = true;
    },
    //保存审核
    async onSaveCheck() {
      this.showCheckDlg = false;
      //审核人员ID
      let userObj = this.$getUserData();
      let check_userid = userObj.auid;

      let obj = {
        theme_id: this.checkItem.theme_id,
        check_status: this.checkItem.check_status,
        check_msg: this.checkItem.check_msg,
        check_userid,
      };
      const res = await this.$post("YHThemeList/saveCheck", obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
    onShowHtml(str = "") {
      let tmp = str.replace(
        "href='/",
        "target ='_blank' " + "href='" + this.$h5Url + "/"
      );
      this.showHtmlText = tmp;

      this.isShow = true;
    },
    //置顶贴子
    async onTopItem(item, top_index) {
      let obj = {
        theme_id: item.theme_id,
        top_index,
      };
      const res = await this.$post("YHThemeList/saveTopItem", obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
  },
};
</script>


<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
.post_contentDiv {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.myHtml {
  width: 100%;
  height: 85vh;
  overflow-y: scroll;
}
.imgDiv {
  max-height: 60px;
  overflow-y: auto;
}
</style>
