import Vue from 'vue'
import Vuex from 'vuex'

//持久化存储
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => window.localStorage.getItem(key),
        setItem: (key, value) => window.localStorage.setItem(key, value),
        removeItem: key => window.localStorage.removeItem(key)
      }
    })
  ],
  state: {
    //缓存的标签页组
    editableTabs: [],
    //当前显示的标签内容
    editableTabsValue: '',
  },
  getters: {
  },
  mutations: {
    //添加一项
    addItem(state, item) {
      state.editableTabs.push(item)
    },
    //删除一项
    delItem(state, name) {
      let index = state.editableTabs.findIndex((item) => {
        return item.name == name;
      });
      if (index > -1) {
        state.editableTabs.splice(index, 1);
        console.log("删除组件", name)
      }

    },
    //清空全部 保留第一个  //是否强制清空，否则保留一个
    clearAll(state, isClear = false) {
      if (state.editableTabs.length < 1) return

      let item = state.editableTabs[0]
      state.editableTabs = []
      if (isClear == false) {
        //保留第一个
        state.editableTabs.push(item)
        state.editableTabsValue = item.name
      }
    },
    //设置当前显示的标签内容
    setTabsValue(state, value) {
      console.log("设置当前显示的标签内容:" + value)
      state.editableTabsValue = value
    }

  },
  actions: {
  },
  modules: {
  }
})
