<template>
	<div id="mytable">
		<!--表格数据区-->
		<el-table v-loading="isLoading" :max-height="maxHeight" :border="true" :data="tableData" highlight-current-row
			stripe :resizable="true">
			<el-table-column v-for="(item, index) in propArr" :key="index" :prop="item.prop"
				:label="item.label?item.label:item.prop" :min-width="item.width" :data-type="item.type"  stripe
				show-overflow-tooltip>
				<template slot="header" slot-scope="headerData">
					<div :title="headerData.column.label">
						{{getTitleLabel(headerData.column.label)}}
					</div>
				</template>
				<template slot-scope="scope">
					<div class="titleDiv">
						{{ scope.row[item.prop] }}
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!--底部分页区-->
		<el-pagination v-if="tableData.length > 0" background @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 30, 50, 100]" :page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		name: 'mytable',
		props: {
			//显示表名称
			showTableName: {
				type: String,
				default: '',
				required: true,
			},
			//其他高度，需除去的
			otherHeight: {
				type: Number,
				default: 150,
			},
			//限制条件
			where: {
				type: String,
				default: ''
			},
			//刷新表格数据
			refresh: {
				type: Number,
				default: 0,
				required: true,
			}
		},
		data() {
			return {
				//表格字段
				propArr: [],
				//表格数据
				tableData: [],
				//总总数
				total: 0,
				//当前的页数
				page: 1,
				//当前的页码显示的条数
				pageSize: 10,
				//最后页号
				last_page: 0,
				//屏幕高度 document.body.clientHeight
				screenHeight: 0,
				//是否获取字段
				isGetFeild: false,
				//正在加载
				isLoading: false,
			};
		},
		created() {
			console.log('mytable 创建')
		},
		watch: {
			//表格名改变时
			showTableName(val) {
				this.isGetFeild = false
			},
			//去除高度改变时
			otherHeight(val) {
				window.screenHeight = document.body.clientHeight
				that.screenHeight = window.screenHeight
			},
			//刷新表格
			refresh(val) {
				console.log('refresh', val)
				this.getDataList();
			},
		},
		mounted() {
			//计算表格高度
			const that = this
			window.screenHeight = document.body.clientHeight
			that.screenHeight = window.screenHeight

			window.onresize = () => {
				return (() => {
					window.screenHeight = document.body.clientHeight
					that.screenHeight = window.screenHeight
					console.log('that.screenHeight', that.screenHeight)
				})()
			}
		},
		computed: {
			maxHeight() {
				return this.screenHeight - this.otherHeight
			}
		},
		methods: {
			//初始化字段信息
			initComment(data) {
				//组合成字段数组
				/*
				COLUMN_COMMENT: "添加时间"
				COLUMN_NAME: "add_time"
				DATA_TYPE: "timestamp"
				*/
				let arr = [];

				for (let i in data) {
					arr.push({
						prop: data[i]["COLUMN_NAME"],
						label: data[i]["COLUMN_COMMENT"],
						type: data[i]["DATA_TYPE"],
						width: data[i]["DATA_TYPE"] == "timestamp" ? 180 : 150,
					});
				}
				this.propArr = arr;
				console.log(this.propArr);
				//通知事件
				this.$on('getComment', this.propArr)
			},
			//获取表数据
			async getDataList() {
				let getField = 0
				if (this.isGetFeild == false) {
					this.isGetFeild = true
					getField = 1 //获取字段
				}
				const postData = {
					tableName: this.showTableName,
					page: this.page,
					pageSize: this.pageSize,
					getField,
					where: this.where
				}
				this.isLoading = true
				const res = await this.$post("ExecTable/getDataList", postData);

				let ret = res.data || [];
				//分析字段
				if (getField == 1) {
					this.initComment(ret.fieldArr || [])
				}

				this.tableData = ret.data;
				this.page = ret.current_page;
				this.last_page = ret.last_page;
				this.total = ret.total;

				console.log(this.tableData);
				//通知事件
				this.$on('getDataList', {
					postData,
					tableData: this.tableData
				})

				this.isLoading = false
			},
			//监听 pagesize 改变的事件
			handleSizeChange(newSize) {
				this.pageSize = newSize;
				this.getDataList();
			},

			//监听 页码值 改变的事件
			handleCurrentChange(newPage) {
				this.page = newPage;
				this.getDataList();
			},
			//取标题，有括号的去除
			getTitleLabel(val) {
				//console.log(val)
				let index = val.lastIndexOf('(')
				if (index > -1) {
					val = val.substring(0, index)
				}
				return val
			}
		},
	};
</script>
<style>
	/*设置显示隐藏部分内容，按50%显示*/
	.el-tooltip__popper {
		font-size: 14px;
		max-width: 50%
	}
</style>
<style lang="less" scoped>
	.titleDiv {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
