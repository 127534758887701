<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户充值管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true">
            <el-form-item label="充值帐号ID">
              <el-input
                clearable
                placeholder="搜索充值帐号ID"
                v-model="chong_auid"
              ></el-input>
            </el-form-item>
            <el-form-item label="充值方式">
              <el-select v-model="chong_type" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in chong_typeArr"
                  :key="index2"
                  :label="item2.title"
                  :value="item2.val"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否确认">
              <el-select v-model="confirm_val" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in confirm_valArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.prop == 'shou_pic'">
                <el-image
                style="width:50px;height:50px;"
                  :src="$getUrl(scope.row[item.prop])"
                  fit="fill"
                  @click="onOpenUrl(scope.row[item.prop])"
                ></el-image>
              </div>
              <div v-else-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.type == 'arrayObj'">
                {{ getOptionVal(item.data, scope.row[item.prop], item.type) }}
              </div>
              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="审核" width="80">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onCheck(scope.row)"
                  >审核</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.cid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.type == 'arrayObj'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2.title"
                  :value="item2.val"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'aid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>

    <!--审核窗口-->
    <el-dialog
      v-if="checkItem"
      :close-on-click-modal="false"
      title="审核并添加财务记录"
      :visible.sync="showCheckDlg"
      width="300"
      :before-close="handleCloseDlgByShenHe"
    >
      <div>
        <div class="checkItem">
          <div>表格ID:</div>
          <div>{{ checkItem.cid }}</div>
        </div>
        <div class="checkItem">
          <div>确认帐号ID:</div>
          <div>{{ checkItem.confirm_auid }}</div>
        </div>

        <div class="checkItem">
          <div>是否确认:</div>
          <el-radio-group v-model="checkItem['confirm_val']">
            <el-radio
              v-for="(item, index) in confirm_valArr"
              :key="index"
              :label="index"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="checkItem">
          <div>确认说明 :</div>
          <el-input type="textarea" clearable v-model="checkItem.confirm_msg">
          </el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCheckDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveCheck">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      chong_auid: "",
      confirm_val: "",
      chong_type: "",
      //显示审核财务记录
      showCheckDlg: false,
      //审核财务记录
      checkItem: null,
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
  `cid` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `chong_auid` int(11) NOT NULL DEFAULT '0' COMMENT '充值帐号ID',
  `chong_type` int(11) NOT NULL COMMENT '充值方式(1=银行 2=支付宝 3=微信 4=其他)',
  `chong_num` float NOT NULL DEFAULT '0' COMMENT '充值金额',
  `chong_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '充值信息',
  `confirm_val` int(11) NOT NULL DEFAULT '0' COMMENT '是否确认(0=未确认 1=确认到帐 2=未到帐)',
  `confirm_auid` int(11) NOT NULL DEFAULT '0' COMMENT '确认帐号ID',
  `confirm_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '确认说明',
  `confirm_time` timestamp NULL DEFAULT NULL COMMENT '确认时间',
       * 
       */
      chong_typeArr: [
        { title: "银行", val: 1 },
        { title: "支付宝", val: 2 },
        { title: "微信", val: 3 },
        { title: "其他", val: 4 },
      ],
      confirm_valArr: ["未确认", "确认到帐", "未到帐"],
      propList: [
        {
          prop: "cid",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "chong_auid",
          label: "充值帐号ID",
        },
        {
          prop: "user_nick",
          label: "充值用户昵称",
          width: 150,
        },
       
        {
          prop: "chong_num",
          label: "充值金额",
        },
        {
          prop: "chong_msg",
          label: "充值信息",
        },
        {
          prop: "confirm_auid",
          label: "确认帐号ID",
        },
        {
          prop: "confirmUserName",
          label: "确认帐号昵称",
          width: 150,
        },
        
        {
          prop: "confirm_msg",
          label: "确认说明",
        },
        {
          prop: "chong_type",
          label: "充值方式",
          type: "arrayObj",
          data: [
            { title: "银行", val: 1 },
            { title: "支付宝", val: 2 },
            { title: "微信", val: 3 },
            { title: "其他", val: 4 },
          ],
        },
        {
          prop: "confirm_val",
          label: "是否确认",
          type: "array",
          data: ["未确认", "确认到帐", "未到帐"],
        },
        {
          prop: "confirm_time",
          label: "确认时间",
          width: 200,
          type: "time",
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },
    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        chong_auid: this.chong_auid,
        confirm_val: this.confirm_val,
        chong_type: this.chong_type,
      };
      const res = await this.$post(`YHChongzhi/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let Obj1 = {
        cid: this.editItem.cid,
        data: this.editItem,
      };
      const res = await this.$post(`YHChongzhi/saveData`, Obj1);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        cid: item.cid,
      };
      console.log(obj2);
      const res = await this.$post(`YHChongzhi/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //打开地址
    onOpenUrl(url) {
      console.log(url);
      if (!url || url.length < 1) return;
      if (url.indexOf("http") != 0) {
        url = this.$imgUrl + url;
      }
      window.open(url, "_black");
    },
    //审核
    onCheck(item) {
      console.log("审核", item);
      this.checkItem = JSON.parse(JSON.stringify(item));
      this.showCheckDlg = true;
    },
    //保存审核
    async onSaveCheck() {
      this.showCheckDlg = false;

      //审核人员ID
      let obj2 = this.$getUserData();
      let confirm_auid = obj2.auid;

      let obj = {
        cid: this.checkItem.cid,
        confirm_val: this.checkItem.confirm_val,
        confirm_auid,
        confirm_msg: this.checkItem.confirm_msg,
      };
      const res = await this.$post("YHChongzhi/saveCheck", obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
  },
};
</script>


<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
</style>
