<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>短信验证码</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <el-form :inline="true" class="searchDiv">
      <el-form-item label="手机号">
        <el-input clearable placeholder="搜索手机号" v-model="phone"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getList()">查询</el-button>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-button type="success" @click="onAdd()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--表格数据区-->
    <el-table
      v-loading="isLoading"
      :height="getH()"
      :border="true"
      :data="tableData"
      highlight-current-row
      strauide
      :resizable="true"
    >
      <el-table-column
        v-for="(item, index) in propArr"
        :key="index"
        :prop="item.prop"
        :label="item.title"
        :min-width="item.width"
        :data-type="item.type"
        align="center"
        
        strauide
        show-overflow-tooltauid
      >
        <template slot="header" slot-scope="headerData">
          <div :title="headerData.column.label">
            {{ getTitleLabel(headerData.column.label) }}
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="item.type == 'array'">
            {{ getOptionVal(item.data, scope.row[item.prop]) }}
          </div>

          <div v-else class="titleDiv">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="caoz">
            <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="onDel(scope.row)"> 删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--底部分页区-->
    <el-pagination
      v-if="tableData.length > 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!--新增、编辑窗口-->
    <el-drawer
      size="60%"
      direction="ltr"
      :visible.sync="showDrawer"
      :with-header="false"
    >
      <div id="" class="myFormDiv">
        <el-row type="flex" justify="end">
          <el-button
            type="primary"
            style="margin-right: 10px; margin-top: 10px"
            @click="onSave"
            >保存
          </el-button>
        </el-row>

        <el-form :inline="false" label-width="160px">
          <el-row>
            <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propArr"
              :key="index"
            >
              <el-form-item :label="getTitleLabel(item.title)">
                <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
                <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      otherHeight: 200,
      //搜索
      phone: "",

      //表格字段
      /*
  `pcid` int(10) unsigned NOT NULL AUTO_INCREMENT,
  `phone` varchar(20) NOT NULL DEFAULT '' COMMENT '手机号',
  `phone_code` varchar(255) DEFAULT NULL COMMENT '最后登录手机验证码',
  `send_time` int(20) DEFAULT '0' COMMENT '最后手机验证码时间戳',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
      */
      msg_typeArr: ["系统消息", "私信", "积分消息", "重要通知"],
      is_readArr: ["未读", "已读"],

      propArr: [
        {
          prop: "pcid",
          title: "ID",
          width: 100,
          type: "",
        },
        {
          prop: "phone",
          title: "手机号",
          width: 100,
          type: "",
        },
        {
          prop: "phone_code",
          title: "最后登录手机验证码",
          width: 150,
          type: "",
        },
        {
          prop: "send_time",
          title: "最后手机验证码时间",
          width: 180,
          type: "time",
        },

        {
          prop: "bz",
          title: "备注",
          width: 150,
          type: "",
        },
      ],
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,
      //是否获取字段
      isGetFeild: false,
      //正在加载
      isLoading: false,
      //显示侧边栏
      showDrawer: false,
      //编辑的项目
      editItem: {},
    };
  },
  created() {
    console.log(" 创建");

    this.initArr();
    this.getList();

    this.initEdit();
  },

  mounted() {
    //计算表格高度
    this.screenHeight = window.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = window.screenHeight = document.body.clientHeight;
      })();
    };
  },
  computed: {
    maxHeight() {
      return this.screenHeight - this.otherHeight;
    },
  },
  methods: {
        //调整table 高度
    getH() {
      return window.innerHeight - 240;
    },
    initArr() {
      //初始化选项值
      for (let i in this.propArr) {
        /*
        if (this.propArr[i]["prop"] == "msg_type") {
          this.propArr[i]["data"] = this.msg_typeArr;
        }
        if (this.propArr[i]["prop"] == "is_read") {
          this.propArr[i]["data"] = this.is_readArr;
        }
        */
      }
    },
    //初始化编辑项目为空
    initEdit() {
      for (let i in this.propArr) {
        this.$set(this.editItem, this.propArr[i]["prop"], "");
      }
      console.log(this.editItem);
    },
    //获取表数据
    async getList(page = 1) {
      const postData = {
        phone: this.phone,
        page,
        pageSize: this.pageSize,
      };
      this.isLoading = true;
      const res = await this.$post("AppPhoneCode/getList", postData);

      let ret = res.data || [];

      this.tableData = ret.data;
      this.page = ret.current_page;
      this.last_page = ret.last_page;
      this.total = ret.total;

      //时间处理
      for (let i in this.tableData) {
        this.tableData[i]["send_time"] = this.$getData(
          this.tableData[i]["send_time"]
        );
      }
      console.log(this.tableData);

      this.isLoading = false;
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(1);
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getList(this.page);
    },
    //取标题，有括号的去除
    getTitleLabel(val) {
      //console.log(val)
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    },
    //编辑内容
    onEdit(item) {
      console.log(item);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.editItem['send_time']=new Date(this.editItem['send_time'])
      this.showDrawer = true;
    },
    //添加内容
    onAdd() {
      this.initEdit();
      this.showDrawer = true;
    },
    //删除内容
    async onDel(item) {
      let pcid = item.pcid;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该ID" + pcid + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$post(`AppPhoneCode/delItem`, {
        pcid,
      });
      console.log("确认删除", res);
      if (res.code !== 200) return;

      //刷新
      this.getList(this.page);
    },
    //保存
    async onSave() {
      this.showDrawer = false;

      let itemData = JSON.parse(JSON.stringify(this.editItem));
      //时间处理
      if (itemData["send_time"]) {
        itemData["send_time"] = this.$getTime(itemData["send_time"]);
      }
      let data = {
        data: itemData,
        pcid: parseInt(this.editItem.pcid),
      };
      const res = await this.$post(`AppPhoneCode/saveData`, data);
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },

  },
};
</script>

<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellauidsis;
  white-space: nowrap;
}

.searchDiv {
  display: flex;
  align-items: center;
}



.myFormDiv {
  height: 100vh;
  overflow-y: scroll;
}

.myElCol {
  padding: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
</style>
