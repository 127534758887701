<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>前台用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户资料</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true">
            <el-form-item label="搜索词">
              <el-input
                clearable
                placeholder="搜索用户信息"
                v-model="searchKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="推广用户ID">
              <el-input
                clearable
                placeholder="输入用户ID"
                v-model="yh_tui_auid"
              ></el-input>
            </el-form-item>
             <el-form-item label="性别">
              <el-select v-model="user_sex" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in user_sexArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.show == 'img'">
                <el-image
                  v-if="scope.row[item.prop]"
                  style="width: 50px; height: 50px"
                  :src="scope.row[item.prop]"
                  fit="fill"
                ></el-image>
              </div>
              <div v-else-if="item.show == 'video'">
                <el-link
                  v-if="scope.row[item.prop]"
                  type="primary"
                  :href="scope.row[item.prop]"
                  target="_blank"
                  >播放视频</el-link
                >
              </div>

              <div v-else class="textareaDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else-if="item.type == 'jsonArr'"
                type="textarea"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      searchKey: "",
      user_sex: "",
      yh_tui_auid: "",

      user_sexArr:["全部", "男生", "女生"],

      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /*
   `id` int(11) NOT NULL AUTO_INCREMENT,
  `user_auid` int(11) NOT NULL DEFAULT '0' COMMENT '用户ID',
  `user_phone` varchar(255) NOT NULL DEFAULT '' COMMENT '用户手机',
  `user_name` varchar(255) NOT NULL DEFAULT '' COMMENT '姓名',
  `user_nick` varchar(255) NOT NULL DEFAULT '' COMMENT '用户昵称',
  `user_level` int(11) NOT NULL DEFAULT '0' COMMENT '用户等级',
  `user_sex` int(11) NOT NULL DEFAULT '0' COMMENT '性别(0=未知 1=男生 2=女生)',
  `user_province` varchar(255) NOT NULL DEFAULT '' COMMENT '所以省份',
  `user_city` varchar(255) NOT NULL DEFAULT '' COMMENT '所在城市',
  `user_county` varchar(255) NOT NULL DEFAULT '' COMMENT '所在区县',
  `user_birthday` int(11) NOT NULL DEFAULT '0' COMMENT '出生日期',
  `user_xingzuo` varchar(255) NOT NULL DEFAULT '' COMMENT '星座',
  `user_height` int(11) NOT NULL DEFAULT '0' COMMENT '身高',
  `user_weight` double(11,2) NOT NULL DEFAULT '0.00' COMMENT '体重',
  `education_level` int(11) NOT NULL DEFAULT '0' COMMENT '学历等级',
  `user_marital` varchar(255) NOT NULL DEFAULT '' COMMENT '婚姻状况',
  `user_trade` varchar(255) NOT NULL DEFAULT '' COMMENT '所在行业',
  `user_work` varchar(255) NOT NULL DEFAULT '' COMMENT '工作岗位',
  `user_wage` int(11) NOT NULL DEFAULT '0' COMMENT '月薪',
  `family_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '家庭情况',
  `user_picture` varchar(500) NOT NULL DEFAULT '' COMMENT '主图地址',
  `user_video` varchar(500) NOT NULL DEFAULT '' COMMENT '视频地址',
  `nature_tag` varchar(255) NOT NULL DEFAULT '' COMMENT '性格标签',
  `want_where_id` int(11) NOT NULL DEFAULT '0' COMMENT '理想条件ID',
  `user_jieshao` text COMMENT '自我介绍',
  `open_contact` varchar(255) NOT NULL DEFAULT '' COMMENT '对外联系方式',
  `yh_tui_auid` int(11) NOT NULL DEFAULT '0' COMMENT '推广用户ID',
  `user_log` varchar(255) NOT NULL DEFAULT '' COMMENT '用户头像',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
*/
      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "更新时间",
          width: 200,
        },
        {
          prop: "user_auid",
          label: "用户ID",
          width: 130,
        },
        {
          prop: "user_phone",
          label: "手机号码",
          width: 150,
        },
        {
          prop: "user_name",
          label: "姓名",
          width: 130,
        },
        {
          prop: "user_nick",
          label: "用户昵称",
        },

        {
          prop: "user_level",
          label: "用户等级",
        },
        {
          prop: "user_sex",
          label: "性别",
          type: "array",
          data: ["未知", "男生", "女生"],
        },
        {
          prop: "user_province",
          label: "所在省份",
        },
        {
          prop: "user_city",
          label: "所在城市",
        },
        {
          prop: "user_county",
          label: "所在区县",
        },

        {
          prop: "user_birthday",
          label: "出生日期",
          width: 130,
        },
        {
          prop: "user_xingzuo",
          label: "星座",
        },

        {
          prop: "user_height",
          label: "身高",
        },
        {
          prop: "user_weight",
          label: "体重",
        },
        {
          prop: "education_level",
          label: "学历等级",
        },
        {
          prop: "user_marital",
          label: "婚姻状况",
        },
        {
          prop: "user_trade",
          label: "所在行业",
        },

        {
          prop: "user_work",
          label: "工作岗位",
        },
        {
          prop: "user_wage",
          label: "月薪",
        },
        {
          prop: "family_msg",
          label: "家庭情况",
          width: 200,
          type: "textarea",
        },
        {
          prop: "user_picture",
          label: "主图地址",
          width: 200,
          type: "textarea",
          show: "img", //显示成控件
        },
        {
          prop: "user_video",
          label: "视频地址",
          width: 200,
          show: "video", //显示成控件
        },
        {
          prop: "nature_tag",
          label: "性格标签",
          type: "jsonArr", //json数组
        },
        {
          prop: "want_where_id",
          label: "理想条件ID",
        },
        {
          prop: "pay_kaifuname",
          label: "开户银行",
        },

        {
          prop: "user_jieshao",
          label: "自我介绍",
          width: 200,
          type: "textarea",
        },
        {
          prop: "open_contact",
          label: "对外联系方式",
          width: 200,
        },

        {
          prop: "user_log",
          label: "用户头像",
          width: 130,
          show: "img", //显示成控件
        },
        {
          prop: "yh_tui_auid",
          label: "推广用户ID",
        },
        {
          prop: "bz",
          label: "备注",
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 270;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        searchKey: this.searchKey,
        user_sex: this.user_sex,
        yh_tui_auid: this.yh_tui_auid,
      };
      const res = await this.$post(`YHUserInfo/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      //把对象转为字符串
      this.editItem.nature_tag =
        this.editItem.nature_tag && this.editItem.nature_tag.join(",");
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: JSON.parse(JSON.stringify(this.editItem)),
      };
      //字符串，则把它分隔成数组
      if (typeof obj.data.nature_tag == "string") {
        obj.data.nature_tag = obj.data.nature_tag.split(",");
      }
      const res = await this.$post(`YHUserInfo/saveData`, obj);
      //console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        id: item.id,
      };
      console.log(obj2);
      const res = await this.$post(`YHUserInfo/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
  },
};
</script>


<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
.textareaDiv {
  overflow: hidden; //多出的隐藏
  text-overflow: ellipsis; //多出部分用...代替
  display: -webkit-box; //定义为盒子模型显示
  -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数
  -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
}
</style>
