<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>财务余额管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true">
            <el-form-item label="用户ID">
              <el-input
                clearable
                placeholder="搜索用户ID"
                v-model="auid"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="计算用户余额" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onCheck(scope.row)"
                  >计算用户余额</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.yid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'yid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      auid: "",
      group_name: "",
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
       * yid int(11) NOT NULL AUTO_INCREMENT,
         up_time timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP COMMENT '最后更新',
         auid int(11) NOT NULL DEFAULT '0' COMMENT '用户ID',
         shouru decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '总收入',
         zhichu decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '总支出',
         yusum decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '当前余额',
         bz varchar(255) DEFAULT '',
       */
      propList: [
        {
          prop: "yid",
          label: "ID",
        },
        {
          prop: "up_time",
          label: "最后更新",
          width: 200,
          type: "time",
        },

        {
          prop: "auid",
          label: "用户ID",
          width: 100,
        },
        {
          prop: "user_nick",
          label: "用户昵称",
          width: 150,
        },
        {
          prop: "user_name",
          label: "用户姓名",
          width: 250,
        },
        {
          prop: "shouru",
          label: "总收入",
        },
        {
          prop: "zhichu",
          label: "总支出",
        },
        {
          prop: "yusum",
          label: "当前余额",
        },
        {
          prop: "bz",
          label: "备注",
          width: 350,
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        auid: this.auid,
      };
      const res = await this.$post(`YHCaiwuYusum/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let Obj1 = {
        yid: this.editItem.yid,
        data: this.editItem,
      };
      const res = await this.$post(`YHCaiwuYusum/saveData`, Obj1);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        yid: item.yid,
      };
      console.log(obj2);
      const res = await this.$post(`YHCaiwuYusum/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //计算用户余额
    async onCheck(item) {
      let obj = {
        auid: item.auid,
      };
      const res = await this.$post(`YHCaiwuYusum/afreshYuSum`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
  },
};
</script>


<style lang="less">
/deep/ .el-table {
  .el-table-body {
    height: 100%;
    margin-top: 5px !important;
  }
}

.el-breadcrumb {
  font-size: 16px !important;
  margin: 0 !important;
}
</style>
<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
</style>
