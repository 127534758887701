import { render, staticRenderFns } from "./AppPhoneCode.vue?vue&type=template&id=c32b8cbc&scoped=true&"
import script from "./AppPhoneCode.vue?vue&type=script&lang=js&"
export * from "./AppPhoneCode.vue?vue&type=script&lang=js&"
import style0 from "./AppPhoneCode.vue?vue&type=style&index=0&lang=less&"
import style1 from "./AppPhoneCode.vue?vue&type=style&index=1&id=c32b8cbc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c32b8cbc",
  null
  
)

export default component.exports