<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>设置管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <div>
      <div class="delDiv">
        <el-button type="primary" @click="onAddSystem(null)">添加</el-button>
        <el-input
          placeholder="搜索中文名称"
          v-model="queryInfo.set_title"
          clearable
        ></el-input>
        <el-button type="primary" @click="onSubmit()"> 搜索</el-button>
      </div>
      <el-table
        :border="true"
        :data="systemList"
        stripe
        :resizable="true"
        :height="getH()"
      >
        <el-table-column
          prop="sid"
          label="ID"
          width="60px"
          
        ></el-table-column>
        <el-table-column
          prop="set_title"
          label="中文名称"
          
        ></el-table-column>
        <el-table-column
          prop="set_name"
          label="英文名称"
          
        ></el-table-column>
        <el-table-column prop="set_val" label="设置值" >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <div class="caoz">
              <el-button @click="onAddSystem(scope.row)">编辑</el-button>
              <el-button @click="onSystemRulesByID(scope.row.sid)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--底部分页区-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--添加dialog表格-->
    <el-dialog
      :title="addForm.sid > 0 ? '编辑招聘系统列表' : '添加招聘系统列表'"
      :visible.sync="addSystemDialogVisible"
      width="50%"
    >
      <el-form :model="addForm" label-width="80px" size="mini">
        <el-form-item label="ID" prop="sid">
          <el-input v-model="addForm.sid"></el-input>
        </el-form-item>
        <el-form-item label="中文名称" prop="set_title">
          <el-input v-model="addForm.set_title"></el-input>
        </el-form-item>
        <el-form-item label="英文名称" prop="set_name">
          <el-input v-model="addForm.set_name"></el-input>
        </el-form-item>
        <el-form-item label="设置值" prop="set_val">
          <el-checkbox
            v-if="isBoolType == true"
            v-model="setValBool"
          ></el-checkbox>

          <el-input type="textarea" v-else v-model="addForm.set_val"></el-input>
        </el-form-item>
        <el-form-item label="是否开关" prop="is_bool">
          <el-checkbox v-model="isBoolType"></el-checkbox>
        </el-form-item>
      </el-form>
      <!--底部-->
      <div class="dialog-footer mybtn">
        <el-button @click="addSystemDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //设置列表
      systemList: [],
      //获取设置列表 对像
      queryInfo: {
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
        set_title: "",
        Boolean: "",
      },
      //总条数
      total: 0,
      //是否布尔值,
      Boolean: ["否", "是"],
      //新增招聘系统设置
      addForm: {
        sid: 0,
        set_title: "",
        set_name: "",
        set_val: "",
        is_bool: "",
      },
      //点击 添加 聊天记录列表弹框 显示与影藏
      addSystemDialogVisible: false,

      //当前项里 设置类型是否逻辑型
      isBoolType: false,
      //当前项里 逻辑型设置值
      setValBool: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getSystemList();
  },
  //判断勾选
  watch: {
    "addForm.is_bool"(val) {
      if (val == 1) {
        this.isBoolType = true;
      } else {
        this.isBoolType = false;
      }
      console.log(val, this.isBoolType, "this.isBoolType");
    },
    "addForm.set_val"(val) {
      if (parseInt(val) == 1) {
        this.setValBool = true;
      } else {
        this.setValBool = false;
      }
      console.log(val, this.setValBool, "this.setValBool");
    },
    isBoolType(val) {
      this.addForm.is_bool = val == true ? 1 : 0;
    },
    setValBool(val) {
      if (this.isBoolType == true) {
        this.addForm.set_val = val == true ? 1 : 0;
      }
    },
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 220;
    },
    //获取设置列表
    async getSystemList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        searchKey: this.queryInfo.set_title,
      };
      const res = await this.$post(`YHSet/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.systemList = res.data.data;
      this.total = res.data.total;
    },

    //点击搜索显示搜索内容
    onSubmit() {
      this.getSystemList();
    },
    //新增或修改聊天记录管理
    onAddSystem(item = null) {
      console.log("玉米", item);
      if (item) {
        for (let i in this.addForm) {
          this.addForm[i] = item[i];
          console.log(i, this.addForm[i]);
        }
      } else {
        for (let i in this.addForm) {
          this.addForm[i] = "";
        }
        this.addForm['is_bool'] =0;
      }
      this.addSystemDialogVisible = true;
    },
    async onSave() {
      for (let key in this.addForm) {
        console.log("hhh", key, this.addForm[key]);
        if (key !== "sid"  && this.addForm[key].length < 1) {
          this.$err("内容不能空:" + key);
          //this.addSystemDialogVisible = false;
          return;
        }
      }

      let obj2 = {
        sid: this.addForm.sid,
        set_name: this.addForm.set_name,
        set_val: this.addForm.set_val,
        set_title: this.addForm.set_title,
        is_bool: this.addForm.is_bool,
      };
      this.addSystemDialogVisible = false;
      const res = await this.$post(`YHSet/saveData`, obj2);

      console.log("目赫尔", res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getSystemList();
    },
    //删除
    async onSystemRulesByID(sid) {
      //弹出对话框是否真的删除 此操作将永久该删除规则
      const confirmResult = await this.$confirm(
        "此操作将永久删除一行数据 ?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$post(`YHSet/delSet`, {
        sid,
      });
      console.log("删除", res);
      if (res.code !== 200) return;
      this.$ok("ok");
      this.getSystemList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getSystemList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取规则列表数据
      this.getSystemList();
    },
  },
};
</script>

<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}
.delDiv {
  margin-top: 12px;
  width: 50%;
  display: flex;
  align-items: center;

  .el-input,
  .el-button {
    margin-left: 10px;
  }
}

.fDiv {
  div {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-tag {
      flex-basis: 1;
      width: 120px !important;
      margin-right: 10px;
    }

    div {
      flex-grow: 1;
    }
  }
}
</style>
