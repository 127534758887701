<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>社区管理</el-breadcrumb-item>
      <el-breadcrumb-item>评论管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <el-form :inline="true" class="myFormDiv">
      <el-form-item label="帖子ID">
        <el-input clearable placeholder="搜索帖子ID" v-model="pid"></el-input>
      </el-form-item>
      <el-form-item label="用户ID">
        <el-input clearable placeholder="搜索用户ID" v-model="auid"></el-input>
      </el-form-item>
      <el-form-item label="评论内容">
        <el-input
          clearable
          placeholder="搜索评论内容"
          v-model="com_text"
        ></el-input>
      </el-form-item>
      <el-form-item label="点赞数大于">
        <el-input
          clearable
          placeholder="输入点赞数"
          v-model="com_favor_num"
        ></el-input>
      </el-form-item>
    </el-form>
    <div id="" style="margin-top: 10px"></div>
    <el-form :inline="true" class="myFormDiv">
      <el-form-item label="审核状态">
        <el-select v-model="ispass" clearable placeholder="请选择">
          <el-option
            v-for="(item2, index2) in ispassArr"
            :key="index2"
            :label="item2"
            :value="index2"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>
      <el-form-item label="倒序">
        <el-checkbox v-model="orderDesc"></el-checkbox>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getList()">查询</el-button>
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>
      <el-form-item>
        <el-button type="warning" @click="onDelSelect()">删除勾选</el-button>
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>

      <el-form-item>
        <el-button type="warning" @click="onDelByPID()">按帖子ID删除</el-button>
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>
      <el-form-item>
        <el-button type="warning" @click="onDelByUserID()"
          >按用户ID删除</el-button
        >
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>

      <el-form-item>
        <el-button type="info" @click="onShowCheckDlg()">审核勾选项</el-button>
      </el-form-item>
      <div id="" style="margin-left: 20px"></div>
      <el-form-item>
        <el-button type="success" @click="onAdd()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--表格数据区-->
    <el-table
      v-loading="isLoading"
      :height="getH()"
      :border="true"
      :data="tableData"
      highlight-current-row
      stripe
      :resizable="true"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="(item, index) in propArr"
        :key="index"
        :prop="item.prop"
        :label="item.title"
        :min-width="item.width"
        :data-type="item.type"
        stripe
        show-overflow-tooltip
      >
        <template slot="header" slot-scope="headerData">
          <div :title="headerData.column.label">
            {{ getTitleLabel(headerData.column.label) }}
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="item.prop == 'ispass' || item.prop == 'com_istop'">
            {{ getOptionVal(item.data, scope.row[item.prop]) }}
          </div>
          <div v-else class="titleDiv">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <div class="caoz">
            <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--底部分页区-->
    <el-pagination
      v-if="tableData.length > 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!--审核评论窗口-->
    <el-dialog
      title="审核评论"
      :close-on-click-modal="false"
      :visible.sync="showCheckDlg"
      width="300"
      :before-close="handleCloseDlgByShenHe"
    >
      <div>
        <div class="checkItem">
          <div>评论ID:</div>
          <div>{{ checkComidArr.join(",") }}</div>
        </div>
        <div class="checkItem">
          <div>审核状态</div>
          <el-radio-group v-model="checkStatus">
            <el-radio
              v-for="(item, index) in ispassArr"
              :key="index"
              :label="index"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="checkItem">
          <div>未通过原因:</div>
          <el-input
            type="textarea"
            clearable
            v-model="checkNoPassMsg"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCheckDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveCheck">确 定</el-button>
      </div>
    </el-dialog>
    <!--编辑评论窗口-->
    <el-dialog
      v-if="editItem"
      title="编辑评论"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
      close-on-click-modal
    >
      <el-form :inline="false" label-width="160px">
        <el-form-item
          :label="item.title"
          v-for="(item, index) in this.propArr"
          :key="index"
        >
          <el-select
            v-if="item.type == 'array'"
            v-model="editItem[item.prop]"
            placeholder="请选择"
          >
            <el-option
              v-for="(item2, index2) in item.data"
              :key="index2"
              :label="item2"
              :value="index2"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-else-if="item.type == 'time'"
            v-model="editItem[item.prop]"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          <el-input
            v-else-if="item.type == 'textarea'"
            type="textarea"
            rows="2"
            clearable
            v-model="editItem[item.prop]"
          >
          </el-input>
          <el-input v-else type="text" clearable v-model="editItem[item.prop]">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      otherHeight: 100,
      //搜索
      pid: "",
      auid: "",
      com_text: "",
      com_favor_num: "",
      //表格字段
      /*
  `comid` bigint(11) NOT NULL AUTO_INCREMENT,
  `ispass` int(11) NOT NULL DEFAULT '0' COMMENT '是否通过(0=待审 1=通过 2=未通过)',
  `nopass_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '未通过原因',
  `pid` int(11) NOT NULL DEFAULT '0' COMMENT '帖子ID',
  `com_storey` int(11) NOT NULL DEFAULT '0' COMMENT '评论楼层',
  `com_istop` int(11) NOT NULL DEFAULT '0' COMMENT '是否置顶',
  `auid` int(11) NOT NULL DEFAULT '0' COMMENT 'app用户ID',
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '评论时间',
  `com_text` varchar(255) NOT NULL DEFAULT '' COMMENT '评论内容',
  `com_pic` varchar(255) NOT NULL DEFAULT '' COMMENT '图片地址(json格式)',
  `com_video` varchar(255) NOT NULL DEFAULT '' COMMENT '视频地址',
  `com_fatherid` int(11) NOT NULL DEFAULT '0' COMMENT '父级ID',
  `com_replyid` int(11) NOT NULL DEFAULT '0' COMMENT '回复评论ID',
  `com_favor_num` int(11) NOT NULL DEFAULT '0' COMMENT '点赞数量',
  `com_reply_auid` int(11) NOT NULL DEFAULT '0' COMMENT '回复对象用户ID',
				*/
      propArr: [
        {
          prop: "comid",
          title: "评论ID",
          width: 150,
        },
        {
          prop: "ispass",
          title: "是否通过",
          width: 150,
          type: "array",
          data: ["待审", "通过", "未通过"],
        },
        {
          prop: "nopass_msg",
          title: "未通过原因",
          width: 150,
          type: "textarea",
        },
        {
          prop: "pid",
          title: "帖子ID",
          width: 150,
        },
        {
          prop: "com_storey",
          title: "评论楼层",
          width: 150,
        },
        {
          prop: "com_istop",
          title: "是否置顶",
          width: 150,
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "auid",
          title: "app用户ID",
          width: 150,
        },

        {
          prop: "postUserName",
          title: "用户昵称",
          width: 150,
        },
        {
          prop: "add_time",
          title: "评论时间",
          width: 180,
          type: "time",
        },
        {
          prop: "com_text",
          title: "评论内容",
          width: 150,
          type: "textarea",
        },
        {
          prop: "com_pic",
          title: "图片地址(json格式)",
          width: 250,
          type: "textarea",
        },
        {
          prop: "com_video",
          title: "视频地址",
          width: 250,
          type: "textarea",
        },
        {
          prop: "com_fatherid",
          title: "父级ID",
          width: 150,
        },
        {
          prop: "com_replyid",
          title: "回复评论ID",
          width: 150,
        },
        {
          prop: "com_favor_num",
          title: "点赞数量",
          width: 150,
        },
        {
          prop: "com_reply_auid",
          title: "回复对象用户ID",
          width: 150,
        },
        {
          prop: "replyUserName",
          title: "回复对象用户昵称",
          width: 150,
        },
      ],
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,
      //是否获取字段
      isGetFeild: false,
      //正在加载
      isLoading: false,
      //审核状态
      ispassArr: ["待审", "通过", "未通过"],
      //是否置顶
      comIstopArr: ["否", "是"],
      //筛选审核状态
      ispass: "",
      //当前选中行组
      selectRow: [],
      //审核窗口显示
      showCheckDlg: false,
      //审核ID组
      checkComidArr: [],
      //审核状态
      checkStatus: "",
      //未通过原因
      checkNoPassMsg: "",

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
      //倒序排列
      orderDesc: true,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    console.log("joblist 创建");
    this.getList();
  },

  mounted() {},
  computed: {},
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 270;
    },
    //获取表数据
    async getList(page = 1) {
      const postData = {
        pid: this.pid.trim(),
        auid: this.auid.trim(),
        com_text: this.com_text.trim(),
        ispass: this.ispass,
        com_favor_num: this.com_favor_num,
        orderDesc: this.orderDesc == true ? 1 : 0,
        page,
        pageSize: this.pageSize,
      };
      this.isLoading = true;
      const res = await this.$post("YHBbsPostComment/getList", postData);

      let ret = res.data || [];

      this.tableData = ret.data;
      this.page = ret.current_page;
      this.last_page = ret.last_page;
      this.total = ret.total;

      console.log(this.tableData);

      this.isLoading = false;
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(1);
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getList(this.page);
    },
    //取标题，有括号的去除
    getTitleLabel(val) {
      console.log(val);
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    },

    //选择框改变
    handleSelectionChange(val) {
      console.log(val);
      this.selectRow = val;
    },
    //删除勾选
    async onDelSelect() {
      if (this.selectRow.length < 1) {
        return this.$err("没有勾选");
      }
      const confirmResult = await this.$confirm(
        "此操作将永久删除勾选评论?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$info("已取消删除");
      }
      let comidStr = "";
      for (let i in this.selectRow) {
        comidStr += this.selectRow[i]["comid"] + ",";
      }
      const res = await this.$post("YHBbsPostComment/delItem", {
        comidStr,
      });
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },
    //审核勾选项
    onShowCheckDlg() {
      if (this.selectRow.length < 1) {
        return this.$err("没有勾选");
      }
      this.checkComidArr = [];
      for (let i in this.selectRow) {
        this.checkComidArr.push(this.selectRow[i]["comid"]);
      }
      this.checkStatus = "";
      this.checkNoPassMsg = "";

      this.showCheckDlg = true;
    },
    //保存审核
    async onSaveCheck() {
      this.showCheckDlg = false;
      let obj = {
        comidStr: this.checkComidArr.join(","),
        ispass: this.checkStatus,
        nopass_msg: this.checkNoPassMsg,
      };
      const res = await this.$post("YHBbsPostComment/saveCheck", obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
    //初始化编辑项目为空
    initEdit() {
      for (let i in this.propArr) {
        this.$set(this.editItem, this.propArr[i]["prop"], "");
      }
      console.log(this.editItem);
    },
    //添加评论
    onAdd() {
      this.initEdit();
      this.showEditDlg = true;
    },
    //编辑评论
    onEdit(item) {
      console.log(item);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    //保存
    async onSaveEdit() {
      this.showEditDlg = false;
      let data = {
        data: this.editItem,
        comid: parseInt(this.editItem.comid),
      };
      const res = await this.$post(`YHBbsPostComment/saveData`, data);
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
    //按帖子ID删除
    onDelByPID() {
      this.$prompt("请输入帖子ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "帖子ID格式不正确",
      })
        .then(({ value }) => {
          if (parseInt(value) > 0) {
            this.callDelByGID(parseInt(value));
          } else {
            this.$err("帖子ID有误");
          }
        })
        .catch(() => {
          this.$info("取消输入");
        });
    },
    async callDelByGID(pid = 0) {
      console.log(pid);
      const res = await this.$post(`YHBbsPostComment/delBbsComment`, {
        pid,
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
    //按用户ID删除
    onDelByUserID() {
      this.$prompt("请输入用户ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "用户ID格式不正确",
      })
        .then(({ value }) => {
          if (parseInt(value) > 0) {
            this.callDelByUserID(parseInt(value));
          } else {
            this.$err("用户ID有误");
          }
        })
        .catch(() => {
          this.$info("取消输入");
        });
    },
    async callDelByUserID(auid = 0) {
      console.log(auid);
      const res = await this.$post(`YHBbsPostComment/delUserComment`, {
        auid,
      });
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
  },
};
</script>
<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myFormDiv {
  display: flex;
  align-items: center;
}
.checkItem {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
