import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import NoFind from '@/views/NoFind.vue'
import NoAccess from '@/views/NoAccess.vue'
//动态加载组件
const requireComponent = require.context(
	// 其组件目录的相对路径
	'../views',
	// 是否查询其子目录
	true,
	// 匹配基础组件文件名的正则表达式
	/\.vue$/
)
//根目录下的组件
let indexArr = ['Login', 'Home', 'NoFind', 'NoAccess']
//Home下的children组件
let childrenArr = [
	{
		path: '/NoFind',
		component: NoFind
	},
	{
		path: '/NoAccess',
		component: NoAccess
	},
]

requireComponent.keys().forEach(fileName => {
	// 获取组件配置
	const componentConfig = requireComponent(fileName)
	// // 获取和目录深度无关的文件名
	const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
	//console.log(componentName)
	if (indexArr.indexOf(componentName) > -1) return
	//添加到数组
	childrenArr.push({
		path: '/' + componentName,
		//component: componentConfig.default || componentConfig,
		name: componentName,
		component: createCustomComponent(componentName, componentConfig.default || componentConfig)
	})

})
/**
 * 将指定组件设置自定义名称
 *
 * @param {String} name 组件自定义名称
 * @param {Component | Promise<Component>} component
 * @return {Component}
 */
export function createCustomComponent(name, component) {
	return {
		name,
		data() {
			return { component: null }
		},
		async created() {
			if (component instanceof Promise) {
				try {
					const module = await component
					this.component = module?.default
				} catch (error) {
					console.error(`can not resolve component ${name}, error:`, error)
				}

				return
			}
			this.component = component
		},
		render(h) {
			return this.component ? h(this.component) : null
		},
	}
}

Vue.use(Router)
//初始化非Home路由
let routes = [
	{
		path: '/',
		redirect: '/Login',
	},
	{
		path: '/Login',
		component: Login
	},
]
//添加home路由
routes.push({
	path: '/Home',
	component: Home,
	children: childrenArr
})


console.log('所有路由', routes)
const router = new Router({
	routes
})

//路由卫士
router.beforeEach((to, from, next) => {
	console.log('to.path=' + to.path, to.matched)
	if (to.path == '/NoFind') {
		next()
	} else {
		//是否有匹配的
		if (to.matched.length < 1) {
			console.log('无组件:', to, to.path)
			next('/NoFind')
		} else {
			//以下不用检测
			let arrNo = ['/Login', '/Home', '/NoAccess', '/NoFind']
			if (arrNo.indexOf(to.path) > -1) {
				next()
				return
			}
			//检测权限
			let classNameList = window.localStorage.getItem("classNameList");
			//console.log('classNameList',classNameList)
			let arr = classNameList.split(',')
			let newArr = arr.map((item) => {
				return '/' + item
			})
			//console.log('权限检测',to.path,newArr)
			if (newArr.indexOf(to.path) > -1) {
				next()
			} else {
				console.log('无权限');
				next('/NoAccess')
			}
		}
	}
})

export default router;