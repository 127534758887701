<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户提现管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true">
            <el-form-item label="收款用户ID">
              <el-input
                clearable
                placeholder="搜索收款用户ID"
                v-model="shou_auid"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否支付">
              <el-select v-model="is_payment" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in is_paymentArr"
                  :key="index2"
                  :label="item2.title"
                  :value="item2.val"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.prop == 'shou_pic'">
                <el-image
                style="width:50px;height:50px;"
                  :src="$getUrl(scope.row[item.prop])"
                  fit="fill"
                  @click="onOpenUrl(scope.row[item.prop])"
                ></el-image>
              </div>
              <div v-else-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.type == 'arrayObj'">
                {{ getOptionVal(item.data, scope.row[item.prop], item.type) }}
              </div>
              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="审核" width="80">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onCheck(scope.row)"
                  >审核</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.pid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'arrayObj'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2.title"
                  :value="item2.val"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'aid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>

    <!--审核窗口-->
    <el-dialog
      v-if="checkItem"
      :close-on-click-modal="false"
      title="审核并添加财务记录"
      :visible.sync="showCheckDlg"
      width="300"
      :before-close="handleCloseDlgByShenHe"
    >
      <div>
        <div class="checkItem">
          <div>表格ID:</div>
          <div>{{ checkItem.pid }}</div>
        </div>
        <div class="checkItem">
          <div>操作帐号ID:</div>
          <div>{{ checkItem.exc_auid }}</div>
        </div>

        <div class="checkItem">
          <div>是否支付:</div>
          <el-radio-group v-model="checkItem['is_payment']">
            <el-radio
              v-for="(item, index) in paymentArr"
              :key="index"
              :label="index"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCheckDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveCheck">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      shou_auid: "",
      is_payment: "",

      //显示审核财务记录
      showCheckDlg: false,
      //审核财务记录
      checkItem: null,
      //审核是否支付
      paymentArr: ["未支付", "已支付", "拒绝"],
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
       * pid int(11) NOT NULL AUTO_INCREMENT,
         add_time timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '申请提现时间',
         shou_auid int(11) NOT NULL DEFAULT '0' COMMENT '收款用户ID',
         shou_msg varchar(255) NOT NULL DEFAULT '' COMMENT '收款信息',
         payment float NOT NULL DEFAULT '0' COMMENT '提现金额',
         pay_msg varchar(255) NOT NULL DEFAULT '' COMMENT '提现说明',
         is_payment int(11) NOT NULL DEFAULT '0' COMMENT '是否支付(0=未支付 1=已支付 -1=拒绝)',
         pay_time timestamp NULL DEFAULT NULL COMMENT '支付时间',
         exc_auid int(11) NOT NULL DEFAULT '0' COMMENT '操作帐号ID',
       * 
       */
      is_paymentArr: [
        { title: "未支付", val: 0 },
        { title: "已支付", val: 1 },
        { title: "拒绝", val: -1 },
      ],

      propList: [
        {
          prop: "pid",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "申请提现时间",
          width: 200,
          type: "time",
        },
        {
          prop: "shou_auid",
          label: "收款用户ID",
        },
        {
          prop: "user_name",
          label: "收款用户姓名",
          width: 150,
        },
        
        {
          prop: "shou_msg",
          label: "收款信息",
          width: 250,
        },
        {
          prop: "payment",
          label: "提现金额",
        },
        {
          prop: "pay_msg",
          label: "提现说明",
        },
        {
          prop: "exc_auid",
          label: "操作帐号ID",
        },

        {
          prop: "ExcUserName",
          label: "操作帐号昵称",
          width: 150,
        },
       
        {
          prop: "is_payment",
          label: "是否支付",
          type: "arrayObj",
          data: [
            { title: "未支付", val: 0 },
            { title: "已支付", val: 1 },
            { title: "拒绝", val: -1 },
          ],
        },
        {
          prop: "pay_time",
          label: "支付时间",
          width: 200,
          type: "time",
        },
      ],
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },
    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        shou_auid: this.shou_auid,
        is_payment: this.is_payment,
      };
      const res = await this.$post(`YHTixian/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let data = JSON.parse(JSON.stringify(this.editItem));
      delete data["user_name"];
      let Obj1 = {
        pid: this.editItem.pid,
        data,
      };
      const res = await this.$post(`YHTixian/saveData`, Obj1);
      console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        pid: item.pid,
      };
      console.log(obj2);
      const res = await this.$post(`YHTixian/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //打开地址
    onOpenUrl(url) {
      console.log(url);
      if (!url || url.length < 1) return;
      if (url.indexOf("http") != 0) {
        url = this.$imgUrl + url;
      }
      window.open(url, "_black");
    },
    //审核
    onCheck(item) {
      console.log("审核", item);
      this.checkItem = JSON.parse(JSON.stringify(item));
      this.showCheckDlg = true;
    },
    //保存审核
    async onSaveCheck() {
      this.showCheckDlg = false;

      //审核人员ID
      let obj2 = this.$getUserData();
      let exc_auid = obj2.auid;

      let obj = {
        pid: this.checkItem.pid,
        is_payment: this.checkItem.is_payment,
        exc_auid,
      };
      const res = await this.$post("YHTixian/saveCheck", obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);

      this.getList();
    },
  },
};
</script>


<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
</style>
