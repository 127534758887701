<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>前台用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户举报</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <div class="delDiv">
        <el-input
          placeholder="搜索举报账号ID"
          v-model="queryInfo.add_auid"
          clearable
        >
        </el-input>

        <el-input
          placeholder="搜索被举报账号ID"
          v-model="queryInfo.jubao_auid"
          clearable
        >
        </el-input>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
      </div>
      <!--列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.nid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'aid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!--底部分页区-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //获取客服管理列表列表对象
      queryInfo: {
        jubao_auid:'',
        add_auid: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
       
      },
      //总数据条数
      total: 0,
      //表格列表
      tableData: [],
      /**
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `auid` int(11) NOT NULL DEFAULT '0' COMMENT '添加账号ID',
  `jubao_auid` int(11) NOT NULL DEFAULT '0' COMMENT '被举报账号ID',
  `msg` text COMMENT '举报内容',
  `is_handle` int(11) NOT NULL DEFAULT '0' COMMENT '是否处理',
  `check_auid` int(11) NOT NULL DEFAULT '0' COMMENT '审核账号ID',
  `check_time` timestamp NULL DEFAULT NULL COMMENT '审核时间',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
       */
      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "auid",
          label: "账号ID",
          width: 100,
        },
        {
          prop: "user_name",
          label: "账号昵称",
          width: 200,
        },
        {
          prop: "jubao_auid",
          label: "被举报账号ID",
          width: 140,
        },
        {
          prop: "jubao_user_name",
          label: "被举报账号昵称",
          width: 200,
        },
        {
          prop: "msg",
          label: "举报内容",
          width: 250,
        },

        {
          prop: "is_handle",
          label: "是否处理",
          type: "array",
          data: ["待处理", "已处理"],
        },
        {
          prop: "check_auid",
          label: "审核账号ID",
          width: 100,
        },
        {
          prop: "check_time",
          label: "审核时间",
          width: 200,
        },
        {
          prop: "bz",
          label: "备注",
          width: 120,
        },
      ],
      //新增禁用功能对像
      editItem: {
        add_time: "",
        auid: "",
        disable_model: "",
        disable_msg: "",
        end_time: "",
        nid: 0,
      },
      // 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 240;
    },
    //获取列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        add_auid: this.queryInfo.add_auid,
        jubao_auid: this.queryInfo.jubao_auid,
      };
      const res = await this.$post(`AppUserJubao/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;
      this.total = res.data.total;
    },
    ////点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取客服管理列表数据
      this.getList();
    },
    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    onEditItem(item = null) {
      this.editItem = item;
      if (item) {
        console.log(item);
        for (let i in this.editItem) {
          this.editItem[i] = item[i];
          console.log(i, this.editItem[i]);
        }
      } else {
        for (let i in this.editItem) {
          this.editItem[i] = "";
        }
      }
      //this.showEditDlg = true;

      this.showEditDlg = true;
    },
    //点击 确定 发起网络请求
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: {
          ...this.editItem,
        },
      };
      const res = await this.$post(`AppUserJubao/saveData`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
      this.showEditDlg = false;
    },
    //删除用户ID
    async onDelItem(item=null) {
      if (item==null) {
        return this.$err("不能为空");
      }
      //弹出对话框是否真的删除 此操作将永久该删除用户积分
      const confirmResult = await this.$confirm(
        "确认删除记录?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        id:item.id,
      };
      const res = await this.$post(`AppUserJubao/delItem`, obj);
      console.log("确认删除", res);
     
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
  },
};
</script>

<style lang="less" scoped>
.delDiv {
  display: flex;
  align-items: center;
  .el-select,
  .el-input,
  .el-button {
    margin-left: 10px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>