<template>
	<div class="logDiv">
		<div class="logDiv_box">
			<div class="tubiao_box">
				<img src="../assets/logo.png" />
			</div>
			<!--表单区域-->
			<div class="login_from">
				<el-tabs v-model="activeName">
					<el-tab-pane label="密码登录" name="pwd">
						<template>
							<el-form v-if="activeName === 'pwd'" prop="loginForm" :model="loginForm"
								:rules="loginFormRules" ref="loginFormRef">
								<el-form-item prop="phone">
									<el-input v-model="loginForm.phone" placeholder="请输入您的手机号"></el-input>
								</el-form-item>
								<el-form-item prop="password">
									<el-input v-model="loginForm.password" placeholder="请输入您的密码" show-password>
									</el-input>
								</el-form-item>
								<el-form-item prop="codeText">
									<img :src="captchaUrl" @click="onGetCaptchaCode" style="
                      width: 160px;
                      height: 62px;
                      border: 1px solid #ccc;
                      border-radius: 5px;
                    " />
									<span style="color: red">点击图片刷新</span>
									<el-input v-model="loginForm.codeText" placeholder="请输入图形验证码"></el-input>
								</el-form-item>
							</el-form>
						</template>
					</el-tab-pane>
					<el-tab-pane label="验证码登录" name="code">
						<template>
							<el-form v-if="activeName === 'code'" prop="loginForm" :model="loginForm"
								:rules="loginFormRules" ref="loginFormRef">
								<el-form-item prop="phone">
									<div class="phoneBtn">
										<el-input placeholder="请输入您的手机号" v-model="loginForm.phone">
										</el-input>
										<el-button type="primary" :disabled="isSendMsg" @click="onSetCode">
											{{ btnTitle }}
										</el-button>
									</div>
								</el-form-item>
								<el-form-item prop="captchaCode">
									<el-input v-model="loginForm.captchaCode" placeholder="请输入您的短信验证码"></el-input>
								</el-form-item>
							</el-form>
						</template>
					</el-tab-pane>
					<div class="myDiv">
						<el-button type="primary" round @click="login">登录</el-button>
					</div>

				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import md5 from "js-md5";

	export default {
		data() {
			return {
				btnTitle: "发送短信",
				isSendMsg: false,
				//登录页面数据绑定
				loginForm: {
					phone: "", //1771259655@qq.com',
					password: "", //admin888',
					codeText: "", //图形验证码
					readonly: false, //验证码已发
					captchaCode: "", //输入的图片验证码

					fromPage: null, //来自页面
					onSetData: "", //手机短信验证
				},
				CaptchaData: null, //图形数据
				//tad 被激活的页签名称
				activeName: "pwd",
				captchaUrl: "", //图形验证码地址
				//表单验证规则对象
				loginFormRules: {
					phone: [{
							required: true,
							message: "请输入手机号",
							trigger: "blur"
						},
						{
							min: 11,
							max: 11,
							message: "长度在 11 个字符",
							trigger: "blur"
						},
					],

					password: [{
							required: true,
							message: "请输入登录密码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 15,
							message: "长度在 6 到 15 个字符",
							trigger: "blur",
						},
					],
					codeText: [{
							required: true,
							message: "请输入图形验证码",
							trigger: "blur"
						},
						{
							min: 4,
							max: 4,
							message: "长度4个字符",
							trigger: "blur",
						},
					],

					captchaCode: [{
							required: true,
							message: "请输入短信验证码",
							trigger: "blur"
						},
						{
							min: 6,
							max: 6,
							message: "长度6个字符",
							trigger: "blur",
						},
					],
				},
			};
		},
		//生命周期函数
		created() {
			console.log("login onLoad");
			//this.$clearUserInfo(); //清空登录信息
			this.onGetCaptchaCode();
		},

		methods: {
			//获取图形验证码
			async onGetCaptchaCode() {
				this.loginForm.codeText = ''
				//this.captchaUrl = this.$host + 'Verify/index?t=' + this.$getTimeStamp()
				const res = await this.$post("Verify/getCatpcha");
				console.log(res);
				if (res.code !== 200) {
					return;
				}
				//保存
				this.CaptchaData = res.data;
				//取出图片
				let imgsrc = this.CaptchaData.base64;
				console.log(imgsrc);

				this.captchaUrl = imgsrc;
				this.captchaCode = ""; //清空输入的验证码
			},
			//获取手机短信验证
			async onSetCode() {
				if (this.loginForm.phone.length !== 11) {
					return this.$err("请输入正确的手机");
				}
				this.isSendMsg = true;
				let obj = {
					phone: this.loginForm.phone,
				};
				const res = await this.$post("AdminLogin/sendSms", obj);
				console.log(res);
				if (res.code !== 200) {
					this.isSendMsg = false;
					return this.$err(res.msg);
				}
				let i = 60;

				let timer = setInterval(() => {
					i--;
					if (i <= 0) {
						clearInterval(timer);
						this.btnTitle = "发送短信";
						this.isSendMsg = false;
					} else {
						this.btnTitle = i + "秒后再次发送";
					}
				}, 1000);
				this.$ok(res.msg);
			},
			login() {
				//validate()是回调函数 正确为true,错误为false
				this.$refs.loginFormRef.validate(async (valid) => {
					//console.log(valid);
					if (!valid) return;
					//判断登录模式   pwd｜code
					let obj2 = null;
					if (this.activeName === "pwd") {
						let code = md5(this.loginForm.codeText);
						console.log(code, this.CaptchaData.md5);
						if (code !== this.CaptchaData.md5) {
							this.$err("图形验证码错误");
							this.onGetCaptchaCode();
							return;
						}
						obj2 = {
							password: this.loginForm.password,
							captchakKey: this.CaptchaData.key,
							captchaCode: this.loginForm.codeText,
						};
					}

					if (this.activeName === "code") {
						obj2 = {
							phonecode: this.loginForm.captchaCode,
						};
					}
					//登录参数
					let obj = {
						type: this.activeName,
						phone: this.loginForm.phone,
						...obj2,
					};
					//发起登录网络请求
					const res = await this.$post("AdminLogin/login", obj);
					console.log(res);
					if (res.code !== 200) {
						if (this.activeName === "pwd") {
							this.onGetCaptchaCode();
						}

						return this.$err(res.msg);
					}
					//保存数据
					let s = JSON.stringify(res.data)
					console.log(s, res.data)
					window.localStorage.setItem("userInfo", s);

					//模块名称与类列表独立保存
					window.localStorage.setItem("power", res.data['power']);
					window.localStorage.setItem("classNameList", res.data['classNameList']); 
					//Vue.prototype.classNameList= res.data['classNameList'] || [];

					this.$router.push("/Home");

				});
			},
		},
	};
</script>



<style lang="less" scoped>
	.myDiv {
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.logDiv {
		background-color: #2b4b6b;
		height: 100%;
	}

	.logDiv_box {
		width: 600px;
		background-color: #fff;
		border-radius: 4px;
		position: absolute; //居中对齐
		left: 50%;
		top: 56%;
		transform: translate(-50%, -50%); //位移自身一半
	}

	.tubiao_box {
		width: 150px;
		height: 150px;
		border: 1px solid #eee;
		border-radius: 50%;
		padding: 5px;
		box-shadow: 0 0 5px #ddd;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: #eee;
		}
	}

	.login_from {
		width: 50%;
		padding: 0 10px;
		margin: 20px auto;
		box-sizing: border-box;
	}

	.btns {
		display: flex;
		justify-content: flex-end;
	}

	.el-tabs {
		margin: 80px auto;
	}

	.el-input {
		margin-block-start: 10px;
		padding-right: 20px;
	}

	.el-button {
		margin-top: 10px;
		/*width: 100%;*/
		height: 80rpx;
	}

	.phoneBtn {
		display: flex;
		justify-content: space-between;
	}
</style>
