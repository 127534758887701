<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>前台用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户积分管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="2" type="flex" align="top">
        <el-col :span="18">
          <el-form :inline="true">
            <el-form-item label="积分类型">
              <el-select
                v-model="queryInfo.jfeType"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in jfeTypeArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="搜索词">
              <el-input
                clearable
                placeholder="搜索说明与备注"
                v-model="queryInfo.query"
              ></el-input>
            </el-form-item>

            <el-form-item label="用户ID">
              <el-input
                clearable
                placeholder="搜索用户ID"
                v-model="queryInfo.auid"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-row type="flex" justify="space-between" align="middle">
            <el-button type="success" @click="onSubmit">搜索</el-button>
            <el-button type="primary" @click="onAddIntegral(null)"
              >添加</el-button
            >
            <el-button @click="onDelByAuid">删除</el-button>
          </el-row>
        </el-col>
      </el-row>

      <!--表格区-->
      <div>
        <el-table
          :border="true"
          :data="IntegralList"
          stripe
          :resizable="true"
          :height="getH()"
        >
          <el-table-column prop="sid" label="ID"></el-table-column>
          <el-table-column
            prop="add_time"
            label="添加时间"
            width="200"
          ></el-table-column>
          <el-table-column prop="auid" label="用户ID"></el-table-column>
          <el-table-column prop="userName" label="用户昵称"></el-table-column>
          <el-table-column prop="add_jifen" label="获得积分"></el-table-column>
          <el-table-column prop="add_msg" label="增加说明"></el-table-column>
          <el-table-column prop="add_bz" label="备注"></el-table-column>
          <el-table-column
            prop="add_type_val"
            label="积分类型"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onAddIntegral(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="onIntegralRulesByID(scope.row.sid)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <!--添加dialog表格-->
    <el-dialog
      :title="addForm.gid > 0 ? '编辑用户积分列表' : '添加用户积分列表'"
      :visible.sync="addIntegralDialogVisible"
      width="50%"
      ><!--表单区-->
      <el-form :model="addForm" label-width="80px" size="mini">
        <el-form-item label="添加时间" prop="add_time">
          <el-date-picker
            v-model="addForm.add_time"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户ID" prop="auid">
          <el-input v-model="addForm.auid"></el-input>
        </el-form-item>
        <el-form-item label="获得积分" prop="add_jifen">
          <el-input v-model="addForm.add_jifen"></el-input>
        </el-form-item>
        <el-form-item label="增加说明" prop="add_msg">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
            v-model="addForm.add_msg"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="add_bz">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
            v-model="addForm.add_bz"
          ></el-input>
        </el-form-item>
        <el-form-item label="积分类型" prop="add_type">
          <el-select v-model="addForm.add_type" placeholder="全部">
            <el-option label="奖励积分" :value="0"></el-option>
            <el-option label="签到所得" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--底部-->
      <div class="dialog-footer mybtn">
        <el-button @click="addIntegralDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </div>
    </el-dialog>

    <!--底部分页区-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //获取客服管理列表列表对象
      queryInfo: {
        query: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
        jfeType: "",
        auid: "",
      },
      //总数据条数
      total: 0,
      //用户积分管理列表
      IntegralList: [],
      // 积分类型数组
      jfeTypeArr: ["奖励积分", "签到所得"],
      //新增用户积分对像
      addForm: {
        add_type: "",
        add_time: "",
        auid: "",
        add_jifen: "",
        add_msg: "",
        add_bz: "",
        sid: 0,
      },
      // 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      //点击添加用户积分列表弹框 显示与影藏
      addIntegralDialogVisible: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getIntegralList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    //获取用户积分列表
    async getIntegralList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        add_type: this.queryInfo.jfeType,
        searchKey: this.queryInfo.query,
        auid: this.queryInfo.auid,
      };
      const res = await this.$post(`AppUserJifen/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.IntegralList = res.data.data;
      this.total = res.data.total;
      //循环 积分类型渲染
      for (let i in this.IntegralList) {
        let n = parseInt(this.IntegralList[i]["add_type"]);
        this.IntegralList[i]["add_type_val"] = this.jfeTypeArr[n];
      }
    },
    ////点击搜索显示搜索内容
    onSubmit() {
      this.getIntegralList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getIntegralList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取客服管理列表数据
      this.getIntegralList();
    },
    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    onAddIntegral(item = null) {
      if (item) {
        console.log(item);
        for (let i in this.addForm) {
          this.addForm[i] = item[i];
          console.log(i, this.addForm[i]);
        }
      } else {
        for (let i in this.addForm) {
          this.addForm[i] = "";
        }
      }
      this.addIntegralDialogVisible = true;
    },
    //点击 确定 发起网络请求
    async onSave() {
      let obj = {
        sid: this.addForm.sid,
        data: {
          ...this.addForm,
        },
      };
      const res = await this.$post(`AppUserJifen/saveData`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getIntegralList();
      this.addIntegralDialogVisible = false;
    },
    //按用户ID删除
    onDelByAuid() {
      this.$prompt("请输入用户ID", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "用户ID格式不正确",
      })
        .then(({ value }) => {
          if (parseInt(value) > 0) {
            this.onIntegralRulesByID(0, parseInt(value));
          } else {
            this.$err("用户ID有误");
          }
        })
        .catch(() => {
          this.$info("取消输入");
        });
    },
    //删除用户ID
    async onIntegralRulesByID(sid = 0, auid = 0) {
      if (sid == 0 && auid == 0) {
        return this.$err("用户ID不能为空");
      }
      //弹出对话框是否真的删除 此操作将永久该删除用户积分
      const confirmResult = await this.$confirm(
        "此操作将永久删除用户积分?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        sid,
        auid,
      };
      const res = await this.$post(`AppUserJifen/delItem`, obj);
      console.log("确认删除", res);
      //判断选择的是行ID(sid)    还是用户ID（auid）
      if (sid == 0 && auid > 0) {
        this.addForm.auid = "";
      }

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getIntegralList();
    },
  },
};
</script>
<style lang="less" scoped>
.delDiv {
  display: flex;
  align-items: center;
  .el-select,
  .el-input,
  .el-button {
    margin-left: 10px;
  }
}
.mybtn {
  display: flex;
  justify-content: flex-end;
}
</style>