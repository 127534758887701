<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>前台用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <div>
        <el-row :gutter="20" type="flex" align="top">
          <el-col :span="18">
            <el-form :inline="true">
              <el-form-item label="关键词搜索">
                <el-input
                  clearable
                  placeholder="搜索用户信息"
                  v-model="searchKey"
                ></el-input>
              </el-form-item>

            </el-form>
          </el-col>
          <el-col :span="2">
            <el-button size="mini" round type="success" @click="getList"
              >搜索</el-button
            >
          </el-col>
           
        </el-row>

        <!--用户列表区-->
        <div class="table">
          <el-table
            :data="userList"
            :border="true"
            :height="getH()"
            stripe
            :resizable="true"
          >
            <el-table-column
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 80"
            >
              <!--显示图片-->
              <template slot-scope="scope">
                <div v-if="item.type == 'array'">
                  {{ getOptionVal(item.data, scope.row[item.prop]) }}
                </div>
                <img
                  v-else-if="scope.column['property'] == 'user_log'"
                  :src="scope.row['user_log_val']"
                  width="20"
                  height="20"
                />

                <div v-else>
                  {{ scope.row[scope.column["property"]] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <div class="caoz">
                  <el-button type="text" size="small" @click="onEdit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="remvRoleById(scope.row)"
                  >
                    删除</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="resetPwd(scope.row)"
                  >
                    重置密码</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!--分页区-->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.vid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <div v-if="item.prop == 'ji_fen_sum'"></div>

            <el-form-item v-else :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'auid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //获取用户参数列表对象
      queryInfo: {
        query: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
      },

      //用户数据列表 默认为空
      userList: [],
      //总数据条数
      total: 0,
      //显示积分
      boolArr: ["否", "是"],
      has_alias: "",
      is_alias: 0,
      //开关
      showJifen: "",
      searchKey: "",
      zhu_auid: "",
      //prop="username" label="姓名"
      propList: [
        {
          prop: "auid",
          label: "用户ID",
        },
        {
          prop: "user_phone",
          label: "手机号",
          width: 150,
        },
        {
          prop: "reg_time",
          label: "注册时间",
          width: 160,
        },
        {
          prop: "lastlogin_time",
          label: "最后登录时间",
          width: 160,
        },
        {
          prop: "lastlogin_info",
          label: "最后登录客户信息",
          width: 140,
        },
        {
          prop: "recommendCode",
          label: "注册推荐码",
          width: 140,
        },
        {
          prop: "tui_userid",
          label: "推广用户ID",
          width: 120,
        },
      ],
      /**
       `tui_userid` int(11) NOT NULL DEFAULT '0' COMMENT '推广用户ID',
  `my_renid` int(11) NOT NULL DEFAULT '0' COMMENT '我的人才ID',
  `h5data` varchar(255) NOT NULL DEFAULT '' COMMENT 'app数据',
  `is_alias` int(11) NOT NULL DEFAULT '0' COMMENT '是否为小号',
  `has_alias` int(11) NOT NULL DEFAULT '0' COMMENT '有小号功能',
  `zhu_auid` int(11) NOT NULL DEFAULT '0' COMMENT '主号ID',
  `show_location` int(11) NOT NULL DEFAULT '0' COMMENT '显示地址',
  `close_info` int(11) NOT NULL DEFAULT '0' COMMENT '关闭信息',
       */
      //控制编辑对话框的显示与隐藏
      showEditDlg: false,
      //控制编辑对话框的对相
      editItem: "",
      radio: "1",
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 330;
    },
    //获取用户参数列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        searchKey: this.searchKey,
        showJifen: this.showJifen,
        has_alias: this.has_alias,
        is_alias: this.is_alias,
        zhu_auid: this.zhu_auid,
      };
      const res = await this.$post(`AppUser/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.userList = res.data.data;
      for (let i in this.userList) {
        if (this.userList[i]["user_log"].length > 0) {
          this.userList[i]["user_log_val"] =
            this.$imgUrl + this.userList[i]["user_log"];
        } else {
          this.userList[i]["user_log_val"] = "";
        }
      }
      // console.log(this.userList,res.data.data)
      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    editRoleDialogClosed() {},
    //点击确定保存修改用户信息
    async onSaveEdit() {
      this.showEditDlg = false;
      let Obj1 = {
        auid: this.editItem.auid,
        data: this.editItem,
      };
      const res = await this.$post(`AppUser/saveData`, Obj1);
      console.log(res);

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async remvRoleById(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        auid: item.auid,
      };
      console.log(obj2);
      const res = await this.$post(`AppUser/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    async resetPwd(item) {
      let obj = await this.$prompt("请输入新密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.{6,15}/,
        inputErrorMessage: "密码长度需6-15",
      }).catch(() => {
        this.$info({
          type: "info",
          message: "取消输入",
        });
        return;
      });
      console.log("obj", obj);
      if (!obj || obj.length < 1) {
        return;
      }
      let pwd = obj.value;
      const confirmResult = await this.$confirm(
        "确认密码重置为: " + pwd + " 吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm") {
        return;
      }

      //提交
      this.callRetSetPwd(item, pwd);
    },
    //提交密码
    async callRetSetPwd(item = null, pwd = "") {
      console.log("pwd", pwd);
      let obj = {
        auid: item.auid,
        user_phone: item.user_phone,
        login_pwd: pwd,
      };
      const res = await this.$post(`AppUser/forget`, obj);
      console.log("提交密码", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
    },
    //同步公司名称
    async onSyncFirmName() {
      const res = await this.$post(`AppUser/syncFirmName`);
      console.log("提交密码", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
  },
};
</script>


<style lang="less">
/deep/ .el-table {
  .el-table-body {
    height: 100%;
    margin-top: 5px !important;
  }
}

.el-breadcrumb {
  font-size: 16px !important;
  margin: 0 !important;
}
</style>
<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
