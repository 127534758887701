<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>主题管理</el-breadcrumb-item>
      <el-breadcrumb-item>主题付款</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="20">
          <el-form :inline="true">
            <el-form-item label="用户ID">
              <el-input
                clearable
                placeholder="搜索用户ID"
                v-model="user_auid"
              ></el-input>
            </el-form-item>

            <el-form-item label="付费状态">
              <el-select v-model="pay_stauts" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in ['待付', '已付']"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--用户列表区 -->
      <div class="table">
        <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>
              <div v-else-if="item.prop == 'post_content'">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">
                    <div v-html="scope.row[item.prop]"></div>
                  </div>
                  <div class="post_contentDiv">{{ scope.row[item.prop] }}</div>
                  >
                </el-tooltip>
              </div>

              <div v-else-if="item.prop == 'post_msg'">
                <el-button
                  type="success"
                  @click="onShowHtml(scope.row[item.prop])"
                  >查看描述</el-button
                >
              </div>
              <div v-else-if="item.prop == 'post_pic'" class="imgDiv">
                <el-image
                  style="width: 50px; height: 50px"
                  v-for="(item, index) in scope.row[item.prop]"
                  :key="index"
                  :src="item"
                  fit="fill"
                  @click="onOpenUrl(item)"
                ></el-image>
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                  type="text"
                  size="small"
                  @click="onEditItem(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页区-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //搜索词
      user_auid: "",
      pay_stauts: "",

      //显示审核订单
      showCheckDlg: false,
      //审核订单内容
      checkItem: null,
      //用户数据列表 默认为空
      tableData: [],
      //总数据条数
      total: 0,
      /**
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `user_auid` int(11) NOT NULL DEFAULT '0' COMMENT '用户ID',
  `pay_theme_id` int(11) NOT NULL DEFAULT '0' COMMENT '付费主题ID',
  `pay_num` double(11,2) NOT NULL DEFAULT '0.00' COMMENT '付费金额',
  `pay_stauts` int(11) NOT NULL DEFAULT '0' COMMENT '付费状态(0=待付 1=已付)',
  `pay_time` timestamp NULL DEFAULT NULL COMMENT '付款时间',
  `pay_msg` varchar(255) NOT NULL DEFAULT '' COMMENT '付款信息',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
       */

      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "add_time",
          label: "添加时间",
          type: "time",
          width: 200,
        },
        {
          prop: "user_auid",
          label: "用户ID",
          width: 200,
        },
        {
          prop: "userNick",
          label: "用户昵称",
          width: 200,
        },
        {
          prop: "pay_theme_id",
          label: "付费主题ID",
          width: 200,
        },
        {
          prop: "pay_num",
          label: "付费金额",
          width: 200,
        },
        {
          prop: "pay_stauts",
          label: "付费状态",
          width: 200,
          type: "array",
          data: ["待付", "已付"],
        },
        {
          prop: "pay_time",
          label: "付款时间",
          width: 200,
          type: "time",
        },
        {
          prop: "pay_msg",
          label: "付款信息",
          width: 200,
        },
        {
          prop: "bz",
          label: "备注",
          width: 300,
        },
      ],

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,

      theme_typeArr: [],
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);

    this.getList();
  },

  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 270;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    handleCloseDlgByShenHe() {
      console.log("handleCloseDlgByShenHe");
      this.showCheckDlg = false;
    },

    //获取列表
    async getList() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        user_auid: this.user_auid,
        pay_stauts: this.pay_stauts,
      };
      const res = await this.$post(`YHThemePay/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;

      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },
    //
    handleClick(row) {
      console.log(row);
    },
    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: JSON.parse(JSON.stringify(this.editItem)),
      };

      const res = await this.$post(`YHThemePay/saveData`, obj);
      //console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        id: item.id,
      };
      console.log(obj2);
      const res = await this.$post(`YHThemePay/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    getUrlList(str) {
      console.log(str);
      if (!str || str.length < 1) return [];
      return str.split(",");
    },

    //打开地址
    onOpenUrl(url) {
      console.log(url);
      if (!url || url.length < 1) return;
      if (url.indexOf("http") != 0) {
        url = this.$imgUrl + url;
      }
      window.open(url, "_black");
    },
  },
};
</script>


<style lang="less" scoped>
.mybtn {
  display: flex;
  justify-content: flex-end;
}

.jifen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.searchDiv {
  display: flex;
  align-items: center;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
.post_contentDiv {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.myHtml {
  width: 100%;
  height: 85vh;
  overflow-y: scroll;
}
.imgDiv {
  max-height: 60px;
  overflow-y: auto;
}
</style>
