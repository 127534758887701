<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <el-form :inline="true" class="searchDiv">
      <el-form-item label="添加用户ID">
        <el-input
          clearable
          placeholder="搜索添加用户ID"
          v-model="q_auid"
        ></el-input>
      </el-form-item>
      <el-form-item label="搜索广告">
        <el-input clearable placeholder="搜索广告" v-model="title"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getList()">查询</el-button>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-button type="success" @click="onAdd()">添加</el-button>
      </el-form-item>
    </el-form>

    <!--表格数据区-->
    <el-table
      v-loading="isLoading"
      :height="getH()"
      :border="true"
      :data="tableData"
      highlight-current-row
      strauide
      :resizable="true"
    >
      <el-table-column
        v-for="(item, index) in propList"
        :key="index"
        :prop="item.prop"
        :label="item.title"
        :min-width="item.width"
        :data-type="item.type"
        align="center"
        strauide
        show-overflow-tooltauid
      >
        <template slot="header" slot-scope="headerData">
          <div :title="headerData.column.label">
            {{ getTitleLabel(headerData.column.label) }}
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="item.type == 'array'">
            {{ getOptionVal(item.data, scope.row[item.prop]) }}
          </div>

          <div v-else class="titleDiv">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="caoz">
            <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="onDel(scope.row)"> 删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--底部分页区-->
    <el-pagination
      v-if="tableData.length > 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.id ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.title">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else-if="item.type == 'jsonArr'"
                type="textarea"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
              <el-upload
                v-if="item.type == 'upload'"
                :action="$baseUrl + 'UpLoad/uploadImgToPublicTmp'"
                :multiple="false"
                :limit="1"
                ref="refImgUp"
                name="image"
                :data="getImgUpData()"
                :before-upload="beforeUploadImg"
                accept=".jpg,.jpeg,.png"
                :on-success="imgUpSuccess"
                :on-error="imgUpErr"
                :show-file-list="false"
              >
                <el-button
                  size="small"
                  :loading="isUpImging"
                  :disabled="isUpImging"
                  style="margin-left: 5px"
                  type="primary"
                  >上传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //搜索
      q_auid: "",
      title: "",

      isUpImging: false,

      //表格字段
      /*
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `auid` int(11) NOT NULL DEFAULT '0' COMMENT '添加人ID',
  `end_time` timestamp NULL DEFAULT NULL COMMENT '过期时间',
  `title` varchar(255) NOT NULL DEFAULT '' COMMENT '广告名称',
  `url_type` int(11) NOT NULL DEFAULT '0' COMMENT '链接类型(0=app内 1=外部)',
  `url` varchar(255) NOT NULL COMMENT '链接地址',
  `msg` varchar(255) NOT NULL DEFAULT '' COMMENT '广告内容',
  `pic` varchar(1024) NOT NULL DEFAULT '' COMMENT '广告图片',
  `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
      */

      propList: [
        {
          prop: "id",
          title: "ID",
          width: 100,
        },
        {
          prop: "add_time",
          title: "添加时间",
          width: 180,
          type: "time",
        },
        {
          prop: "auid",
          title: "添加人ID",
          width: 100,
        },

        {
          prop: "end_time",
          title: "过期时间",
          width: 180,
          type: "time",
        },

        {
          prop: "url_type",
          title: "链接类型",
          width: 150,
          type: "array",
          data: ["app内", "外部"],
        },
        {
          prop: "url",
          title: "链接地址",
          width: 200,
        },
        {
          prop: "msg",
          title: "广告内容",
          width: 200,
        },
        {
          prop: "pic",
          title: "广告图片",
          width: 200,
          type: "upload",
        },
        {
          prop: "bz",
          title: "备注",
          width: 200,
        },
      ],
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,
      //是否获取字段
      isGetFeild: false,
      //正在加载
      isLoading: false,
      //显示侧边栏
      showEditDlg: false,
      //编辑的项目
      editItem: {},
    };
  },
  created() {
    console.log(" 创建");

    this.initArr();
    this.getList();

    this.initEdit();
  },

  mounted() {},
  computed: {},
  methods: {
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let obj = {
        id: this.editItem.id,
        data: JSON.parse(JSON.stringify(this.editItem)),
      };

      const res = await this.$post(`APPAdList/saveData`, obj);
      //console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //调整table 高度
    getH() {
      return window.innerHeight - 300;
    },
    initArr() {
      //初始化选项值
      for (let i in this.propList) {
        if (this.propList[i]["prop"] == "msg_type") {
          this.propList[i]["data"] = this.msg_typeArr;
        }
        if (this.propList[i]["prop"] == "is_read") {
          this.propList[i]["data"] = this.is_readArr;
        }
      }
    },
    //初始化编辑项目为空
    initEdit() {
      for (let i in this.propList) {
        this.$set(this.editItem, this.propList[i]["prop"], "");
      }
      console.log(this.editItem);
    },
    //获取表数据
    async getList(page = 1) {
      const postData = {
        q_auid: this.q_auid,

        title: this.title,
        page,
        pageSize: this.pageSize,
      };
      this.isLoading = true;
      const res = await this.$post("APPAdList/getList", postData);

      let ret = res.data || [];

      this.tableData = ret.data;
      this.page = ret.current_page;
      this.last_page = ret.last_page;
      this.total = ret.total;

      console.log(this.tableData);

      this.isLoading = false;
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(1);
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getList(this.page);
    },
    //取标题，有括号的去除
    getTitleLabel(val) {
      //console.log(val)
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    },
    //编辑内容
    onEdit(item) {
      console.log(item);
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    //添加内容
    onAdd() {
      this.initEdit();
      this.showEditDlg = true;
    },
    //删除内容
    async onDel(item) {
      let id = item.id;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该ID" + id + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$post(`APPAdList/delItem`, {
        id,
      });
      console.log("确认删除", res);
      if (res.code !== 200) return;

      //刷新
      this.getList(this.page);
    },
    //保存
    async onSave() {
      this.showEditDlg = false;
      let data = {
        data: this.editItem,
        id: parseInt(this.editItem.id),
      };
      const res = await this.$post(`APPAdList/saveData`, data);
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList(this.page);
    },
    //上传图片附加参数
    getImgUpData() {
      let arr = this.$getSendParam();
      delete arr["data"];

      let obj = {};
      for (let i in arr) {
        console.log(i, arr[i]);
        obj[i] = arr[i];
      }
      return obj;
    },
    //准备上传时
    beforeUploadImg(file) {
      this.isUpImging = true;
      return true;
    },
    //图片上传成功
    imgUpSuccess(res, file, fileList) {
      this.isUpImging = false;
      //this.$refs["refImgUp"].clearFiles();
      if (res.code !== 200) {
        this.$err(res.msg);
        return;
      }
      //上传成功时,获取图片URL
      let fileName = res.data;
      //图片链接
      this.editItem.url = fileName;
    },
    //图片上传失败
    imgUpErr(err, file, fileList) {
      //this.$refs["refImgUp"].clearFiles();
      console.log("文件上传失败");
      this.$err(err);
      this.isUpImging = false;
    },
  },
};
</script>

<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellauidsis;
  white-space: nowrap;
}

.searchDiv {
  display: flex;
  align-items: center;
}

.myFormDiv {
  height: 100vh;
  overflow-y: scroll;
}

.myElCol {
  padding: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}

</style>
