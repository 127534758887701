<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>前台用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>推广返佣</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true">
            <el-form-item label="消费用户ID">
              <el-input
                clearable
                placeholder="输入消费用户ID"
                v-model="pay_auid"
              ></el-input>
            </el-form-item>

            <el-form-item label="消费表ID">
              <el-input
                clearable
                placeholder="输入消费表ID"
                v-model="pay_id"
              ></el-input>
            </el-form-item>

            <el-form-item label="上级ID">
              <el-input
                clearable
                placeholder="搜索上级ID"
                v-model="father_auid"
              ></el-input>
            </el-form-item>
            <el-form-item label="上上级ID">
              <el-input
                clearable
                placeholder="输入上上级ID"
                v-model="base_auid"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onEditItem(null)"
            >新增</el-button
          >
        </el-col>
      </el-row>

      <!--表格区-->
      <div>
        <!--用户列表区 -->
        <div class="table">
          <el-table
            :data="tableData"
            :border="true"
            :height="getH()"
            stripe
            :resizable="true"
          >
            <el-table-column
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
            >
              <template slot-scope="scope">
                <div v-if="item.type == 'array'">
                  {{ getOptionVal(item.data, scope.row[item.prop]) }}
                </div>
                <div v-else-if="item.show == 'img'">
                  <el-image
                    v-if="scope.row[item.prop]"
                    style="width: 50px; height: 50px"
                    :src="scope.row[item.prop]"
                    fit="fill"
                  ></el-image>
                </div>

                <div v-else class="textareaDiv">
                  {{ scope.row[item.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
              <template slot-scope="scope">
                <div class="caoz">
                  <el-button
                    type="text"
                    size="small"
                    @click="onEditItem(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="onDelItem(scope.row)"
                  >
                    删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.fid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="item2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'aid'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!--底部分页区-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //获取客服管理列表列表对象
      queryInfo: {
        query: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
        auid: "",
      },

      father_auid: "",
      base_auid: "",
      pay_auid: "",
      pay_id: "",
      //总数据条数
      total: 0,
      //禁用功能列表
      tableData: [],
      /**
  `fid` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP,
  `pay_auid` int(11) NOT NULL DEFAULT '0' COMMENT '消费账号ID',
  `pay_id` int(11) NOT NULL DEFAULT '0' COMMENT '消费表ID',
  `pay_num` double(11,2) NOT NULL DEFAULT '0.00' COMMENT '消费金额',
  `father_auid` int(11) NOT NULL DEFAULT '0' COMMENT '上级ID',
  `father_cps` double(11,2) NOT NULL DEFAULT '0.00' COMMENT '上级返佣金',
  `base_auid` int(11) NOT NULL DEFAULT '0' COMMENT '上上级ID',
  `base_cps` double(11,2) NOT NULL DEFAULT '0.00' COMMENT '上上级返佣金',
       */
      propList: [
        {
          prop: "fid",
          label: "ID",
        },

        {
          prop: "add_time",
          label: "添加时间",
          width: 200,
          type: "time",
        },
        {
          prop: "pay_auid",
          label: "消费账号ID",
          width: 100,
        },
        {
          prop: "payUserNick",
          label: "消费帐号昵称",
          width: 150,
        },
        {
          prop: "pay_id",
          label: "消费表ID",
          width: 100,
        },

        {
          prop: "pay_num",
          label: "消费金额",
          width: 100,
        },
        {
          prop: "father_auid",
          label: "上级ID",
          width: 100,
        },
        {
          prop: "fatherUserNick",
          label: "上级账号昵称",
          width: 150,
        },
        {
          prop: "father_cps",
          label: "上级返佣金",
          width: 100,
        },
        {
          prop: "base_auid",
          label: "上上级ID",
          width: 100,
        },
        {
          prop: "baseUserNick",
          label: "上上级账号昵称",
          width: 150,
        },
        {
          prop: "base_cps",
          label: "上上级返佣金",
           width: 150,
        },
      ],
      //新增禁用功能对像
      editItem: {
        add_time: "",
        auid: "",
        disable_model: "",
        disable_msg: "",
        end_time: "",
        fid: 0,
      },
      // 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },

      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    //获取禁用功能列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        pay_auid: this.pay_auid,
        father_auid: this.father_auid,
        base_auid: this.base_auid,
        pay_id:this.pay_id,
      };
      const res = await this.$post(`YHFenyongUserPay/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;
      this.total = res.data.total;
    },
    ////点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取客服管理列表数据
      this.getList();
    },

    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    onEditItem(item = null) {
      this.editItem = item;
      if (item) {
        console.log(item);
        for (let i in this.editItem) {
          this.editItem[i] = item[i];
          console.log(i, this.editItem[i]);
        }
      } else {
        console.log("onAdd");
        let obj = {};
        for (let i in this.propList) {
          let prop = this.propList[i]["prop"];
          this.$set(obj, prop, "");
        }
        this.editItem = obj;
      }
      //this.showEditDlg = true;

      this.showEditDlg = true;
    },
    //点击 确定 发起网络请求
    async onSaveEdit() {
      let obj = {
        fid: this.editItem.fid,
        data: {
          ...this.editItem,
        },
      };
      const res = await this.$post(`YHFenyongUserPay/saveData`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
      this.showEditDlg = false;
    },
    //删除用户ID
    async onDelItem(item = null) {
      if (item == null) {
        return this.$err("不能为空");
      }
      //弹出对话框是否真的删除 此操作将永久该删除用户积分
      const confirmResult = await this.$confirm("确认删除记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        fid: item.fid,
      };
      const res = await this.$post(`YHFenyongUserPay/delItem`, obj);
      console.log("确认删除", res);

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
  },
};
</script>

<style lang="less" scoped>
.delDiv {
  display: flex;
  align-items: center;
  .el-select,
  .el-input,
  .el-button {
    margin-left: 10px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>